import React, { Component } from 'react'
import axios from 'axios';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Button } from 'semantic-ui-react'
import { Container } from 'react-bootstrap';

class Login extends Component {
  constructor() {
    super()
    this.state =  {
      errors: [],
      loading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleSubmit (event) {
    event.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const request = {"user": {"email": email, "password": password}};

    this.setState({
      loading: true
    });
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/auth/login`, headers: { 'Content-Type': 'application/json',  'Access-Control-Allow-Origin': '*' }, data: request})
      .then((response) => {
        localStorage.setItem("jwt", response.data.access_token);
        this.setState((state) => ({
          isAdmin: true,
          loading: false
        }))
        localStorage.setItem("admin", true);
        window.location.replace("/");
      })
      .catch((error) => {
        this.setState((state) => ({
          errors: error
        }))
      })
  };

  render() {
    let data;

    if (this.state.loading)
    {
      data =
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    }
    else
    {

    data =
      <div>
      <Container>
        <h1>Log In</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email: </label>
            <input name="email" id="email" type="email" className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input name="password" id="password" type="password" className="form-control" />
          </div>

          <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
              <div>
                {this.state.errors.map(function(error, index) {
                  return <span key={index}>sddsds{ error}</span>;
                })}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <Button type="submit" style={{float:'Right'}} className="btn dark-red btn-dark">Submit</Button>
            </Col>
          </Row>
        </form>
      </Container>
    </div>

  }
  return (
    <div className='main-container'>
    {data}
    </div>

  );
  }
}

export default Login;
