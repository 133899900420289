import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Spinner, Modal, Table, Breadcrumb} from 'react-bootstrap';
import { Button} from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faCheck, faTimes);

class CustomerJobInfo extends Component {
  constructor() {
    super();
    this.state = {
      job: {},
      customer: {},
      car: {},
      tasks: [],
      job_loading: true,
      task_loading: true,
      customer_loading: true,
      car_loading: true,
      showTaskModal: false,
      taskName: '',
      taskDescription: '',
      taskEstimatedTime: '',
      taskActualTime: '',
      taskOperator: '',
      taskPartsUsed: '',
      taskMaterialCost: '',
      taskLabourCost: '',
      taskComplete: false
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleTaskChange = this.handleTaskChange.bind(this);
    this.handleTaskModalOpen = this.handleTaskModalOpen.bind(this);
    this.handleTaskModalClose = this.handleTaskModalClose.bind(this);
    this.handleTaskSubmit = this.handleTaskSubmit.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    console.log(this.props.match.params.car_id);
    console.log(this.props.match.params.customer_id);
    console.log(this.props.match.params.job_id);
    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs/${this.props.match.params.job_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
      this.setState({
        job: response.data,
        job_loading: false
        })
    })
    .catch(error => console.log('error', error));

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs/${this.props.match.params.job_id}/tasks`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
      this.setState({
        tasks: response.data,
        task_loading: false
        })
    })
    .catch(error => console.log('error', error));

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
      this.setState({
        car: response.data,
        car_loading: false
        })
    })

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
    this.setState({
      customer: response.data,
      customer_loading: false
      })
    })
    .catch(error => console.log('error', error));
  }

  handleDelete() {
    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/${this.props.match.params.id}`})
      .then(() => {
        this.props.history.push("/cars")
      })
    .catch(error => console.log('error', error));
  }

  handleCardClick = (id) => {
   this.props.history.push(`/api/cars/${id}`)
  }

  handleTaskModalOpen(){
    this.setState({
      showTaskModal: true
    })
  }

  handleTaskModalClose(){
    this.setState({ showTaskModal: false });
  }

  handleTaskSubmit(event){
    var form_data = {
                      'name': this.state.taskName,
                      'description': this.state.taskDescription,
                      'estimated_time': this.state.taskEstimatedTime,
                      'actual_time': this.state.taskActualTime,
                      'operator': this.state.taskOperator,
                      'parts_used': this.state.taskPartsUsed,
                      'material_cost': this.state.taskMaterialCost,
                      'labour_cost': this.state.taskLabourCost,
                      'complete': this.state.taskComplete,
                      'customer_id': this.props.match.params.customer_id,
                      'car_id': this.props.match.params.car_id,
                      'job_id': this.props.match.params.job_id,
                    }
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem('jwt');
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs/${this.props.match.params.job_id}/tasks`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: form_data})
      .then((response) => {
        this.setState({ showTaskModal: false });
      })
      .catch(error => this.setState({ errors: error.response.data.error }));
  }

  handleTaskChange(event) {

    event.preventDefault();
    this.setState(
      { [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value }
    );
  }

  handleCheckboxChange(event) {

    const target = event.target;
    const value = target.name === 'taskComplete' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });

  }

  renderTaskModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.showTaskModal === true) {
      return (
        <div>
          <Modal.Header closeButton>
            <Modal.Title id='enquiry-modal-title'>
              Add Task
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className='rounded' style={{border:'none'}}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Form id='contact-form'>
                    <Form.Group controlId='contactForm.ControlInput1'>
                      <Form.Control type='name' name='taskName' placeholder='Title' onChange={this.handleTaskChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlInput2'>

                      <Form.Control as='textarea' rows='5' name='taskDescription' placeholder='Description' onChange={this.handleTaskChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='number' name='taskEstimatedTime' placeholder='Estimated Time (Hours)' onChange={this.handleTaskChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='number' name='taskActualTime' placeholder='Actual Time (Hours)' onChange={this.handleTaskChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='text' name='taskOperator' placeholder='Operator' onChange={this.handleTaskChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='text' name='taskPartsUsed' placeholder='Parts Used' onChange={this.handleTaskChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='text' name='taskMaterialCost' placeholder='Material Cost (£)' onChange={this.handleTaskChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='text' name='taskLabourCost' placeholder='Labour Cost (£)' onChange={this.handleTaskChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Label>Complete</Form.Label>
                      <Form.Control type="checkbox" checked={this.state.taskComplete} name='taskComplete' label='Complete' onChange={this.handleCheckboxChange} />
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Button variant="danger" type="button" onClick={()=>this.handleClear()} className="ui red button">Clear</Button>
                      <Button variant="success" style={{float:'right'}} type="submit" onClick={this.handleTaskSubmit} className={`ui red button`}>Submit</Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
        </div>
      );
    }
  }

  render() {
    let data;
    if (this.state.car_loading || this.state.jobs_loading)
    {
      data =
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    }
    else
    {
      data =



      <div>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href={`/customers/search`}>Customers</Breadcrumb.Item>
                <Breadcrumb.Item href={`/customers/${this.props.match.params.customer_id}/`}>{this.state.customer.forename}</Breadcrumb.Item>
                <Breadcrumb.Item active>Cars</Breadcrumb.Item>
                <Breadcrumb.Item href={`/customers/${this.props.match.params.car_id}/car/${this.props.match.params.car_id}`}>{this.state.car.registration}</Breadcrumb.Item>
                <Breadcrumb.Item active>Jobs</Breadcrumb.Item>
                <Breadcrumb.Item active>{this.props.match.params.customer_id}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col className='mb-4' xs={12} sm={12} md={12} lg={12}>
              <Card.Title><h2>Job - {this.state.job.title}</h2></Card.Title>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12}>
              <p>{this.state.job.description}</p>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12}>
              <h2>Tasks</h2>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className='mobileRemove'>Description</th>
                    <th>Estimated Time</th>
                    <th>Actual Time</th>
                    <th>Operator</th>
                    <th className='mobileRemove'>Parts Used</th>
                    <th>Cost</th>
                    <th>Complete</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tasks.map((task, index) =>(
                    <tr>
                      <td>{task.name}</td>
                      <td className='mobileRemove'>{task.description}</td>
                      <td>{task.estimated_time}</td>
                      <td>{task.actual_time}</td>
                      <td>{task.operator}</td>
                      <td className='mobileRemove'>{task.parts_used}</td>
                      <td>{Number(task.material_cost) + Number(task.labour_cost)}</td>
                      <td>
                        {task.complete ? <FontAwesomeIcon icon='check' size='lg' style={{display:'inline-block', float:'right', color:'green'}}/> : <FontAwesomeIcon icon='times' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>}
                      </td>
                    </tr>
                    )
                  )}
                </tbody>
              </Table>
              <Button className='ui dark-red button' onClick={this.handleTaskModalOpen}>Add Task</Button>
              <Button className='ui red button' href={`/customers/${this.props.match.params.customer_id}/car/${this.props.match.params.car_id}/job/${this.props.match.params.job_id}/invoice`}>Generate Invoice</Button>

            </Col>

          </Row>
        </Container>
      </div>
      }
      return (
        <div className='main-container'>
        {data}

        <Modal show={this.state.showTaskModal}
               onHide={this.handleTaskModalClose}
               size="lg"
               centered>
          {this.renderTaskModal()}
        </Modal>
        </div>

      );
    }
  }

export default CustomerJobInfo;
