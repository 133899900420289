import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { Container, Form, Row, Col, ButtonToolbar, Spinner, Card, Image, Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import FileBase64 from 'react-file-base64';
import { Dropdown, Header } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt);

const features = [
  { key: 'Air Conditioning', text: 'Air Conditioning', value: 'Air Conditioning' },
  { key: 'Alloy Wheels', text: 'Alloy Wheels', value: 'Alloy Wheels' },
  { key: 'Bluetooth', text: 'Bluetooth', value: 'Bluetooth' },
  { key: 'Remote Control Locking', text: 'Remote Control Locking', value: 'Remote Control Locking' },
  { key: 'Cruise Control', text: 'Cruise Control', value: 'Cruise Control' },
  { key: 'Isoflex Childseat Fitting', text: 'Isoflex Childseat Fitting', value: 'Isoflex Childseat Fitting' },
  { key: 'Parking Sensors', text: 'Parking Sensors', value: 'Parking Sensors' },
  { key: 'Roof Rails', text: 'Roof Rails', value: 'Roof Rails' },
  { key: 'Sat Nav', text: 'Sat Nav', value: 'Sat Nav' },
  { key: 'Heated Seats', text: 'Heated Seats', value: 'Heated Seats' },
  { key: 'Electric Sunroof', text: 'Electric Sunroof', value: 'Electric Sunroof' },
  { key: 'Leather Upholstery', text: 'Leather Upholstery', value: 'Leather Upholstery' }
]

class CarAdd extends React.Component {
  constructor() {
    super();
    this.state =  {
      registration: '',
      make: '',
      model: '',
      year: 0,
      colour: '',
      body_type: 'Hatchback',
      fuel_type: 'Petrol',
      mileage: 0,
      engine_size: 0.0,
      transmission: 'Manual',
      doors: 4,
      seats: 5,
      features: [],
      description: '',
      price: 0,
      primary_image: [],
      mot: false,
      mot_date: '',
      images: [],
      for_sale: true,
      clientSideErrors: {
        registration: '',
        make: '',
        model: '',
        year: '',
        colour: '',
        body_type: '',
        fuel_type: '',
        mileage: '',
        engine_size: '',
        transmission: '',
        doors: '',
        seats: '',
        features: '',
        description: '',
        mot: '',
        mot_date: '',
        price: '',
        primary_image: '',
        images: ''
      },
      errors: [],
      modalCar: {},
      showModal: false,
      isPreviewReady: false,
      loading: false
    };
    this.getDropdownValue = this.getDropdownValue.bind(this);
    this.checkPreviewStatus = this.checkPreviewStatus.bind(this);
    this.clientSideValid = this.clientSideValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEngineSizeChange = this.handleEngineSizeChange.bind(this);
    this.handleMOTChange = this.handleMOTChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleSubmit(event) {
    this.setState({
      loading: true
    })

    event.preventDefault();
    if (localStorage.getItem("jwt")){
      let token = "Bearer " + localStorage.getItem("jwt");
      axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: this.state})
        .then((response) => {
          this.setState({
            loading: false
          })
          this.props.history.push(`/cars/${response.data.id}`);
        })
        .catch(error => {
          this.setState({ errors: error.response.data.error, loading: false });
        });
    }
    else{
      this.props.history.push("/")
    }
  }

  handleModalOpen(){
    this.setState({
      modalCar: this.state,
      showModal: true
    })
  }

  handleModalClose(){
    this.setState({ showModal: false });
  }

  checkPreviewStatus(){
    const {registration, make, model, year, colour, body_type, fuel_type, mileage, engine_size, transmission, doors, seats, features, description, price, primary_image, images} = this.state
    if (registration && make && model && year && colour && body_type && fuel_type && mileage && engine_size && transmission && doors && seats && features && description && price && primary_image && primary_image.length > 0 && images && images.length > 0)
    {
      this.setState({ isPreviewReady: true });
    }
  }

  handleChange(event) {
    event.preventDefault();
    if (event.target.name === 'registration') {
      this.setState(
        { [event.target.name]: event.target.value.toUpperCase() },
        this.checkPreviewStatus()
      );
    }
    else{
      this.setState(
        { [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value },
        this.checkPreviewStatus()
      )
    }
  };

  handleEngineSizeChange(event) {
    event.preventDefault();
    this.setState(
      { [event.target.name]: event.target.type === 'number' ? parseFloat(event.target.value).toFixed(1) : event.target.value },
      this.checkPreviewStatus()
    );
  }

  handleMOTChange(event) {
    console.log(event.target.checked)
    this.setState(
      { mot: event.target.checked }
    );

  }

  clientSideValid(event) {
    const { name, value } = event.target;
    let clientSideErrors = this.state.clientSideErrors;
    switch (name) {
      case 'registration':
        clientSideErrors.registration =
          value.length < 5
            ? 'Registration must be at least 5 characters long.'
            : '';
        break;
      case 'make':
        clientSideErrors.make =
          value.length === ''
            ? 'Make must be selected.'
            : '';
        break;
      case 'model':
        clientSideErrors.model =
          value.length <= 0
            ? 'Model must be selected.'
            : '';
        break;
      case 'year':
        clientSideErrors.year =
          value > parseFloat(new Date().getFullYear()) || value < 1940
            ? 'Year must be between 1950 and ' + new Date().getFullYear()
            : '';
        break;
      case 'colour':
        clientSideErrors.colour =
          value.length <= 0
            ? 'Colour must be selected.'
            : '';
        break;
      case 'body_type':
        clientSideErrors.body_type =
          value.length <= 0
            ? 'Body type must be selected.'
            : '';
        break;
      case 'fuel_type':
        clientSideErrors.fuel_type =
          value.length < 5
            ? 'Fuel type must be selected.'
            : '';
        break;
      case 'mileage':
        clientSideErrors.mileage =
          value > 1000000 || value <= 0
            ? 'Mileage must be between 0 and 1,000,000 miles.'
            : '';
        break;
      case 'engine_size':
        clientSideErrors.engine_size =
          value > 0.5 || value <= 8
            ? 'Engine size must be selected.'
            : '';
        break;
      case 'transmission':
        clientSideErrors.transmission =
          value.length <= 0
            ? 'Transmission must be selected.'
            : '';
        break;
      case 'doors':
        clientSideErrors.doors =
          value > 2 || value < 8
            ? 'Doors must be between 3 and 7.'
            : '';
        break;
      case 'seats':
        clientSideErrors.seats =
          value > 2 || value < 50
            ? 'Seats must be between 3 and 50.'
            : '';
        break;
      case 'features':
        clientSideErrors.features =
          value.length < 4
            ? 'You must select at least 4 features.'
            : '';
        break;
      case 'description':
        clientSideErrors.description =
          value.length < 150
            ? 'Description must be at least 150 characters long.'
            : '';
        break;
      case 'price':
        clientSideErrors.price =
          value.length < 5
            ? 'Model must be at least 5 characters long.'
            : '';
        break;
      case 'primary_image':
        clientSideErrors.primary_image =
          value.length < 5
            ? 'Model must be at least 5 characters long.'
            : '';
        break;
      case 'images':
        clientSideErrors.images =
          value.length < 5
            ? 'Model must be at least 5 characters long.'
            : '';
        break;
      default:
        break;
    }
    //
    // Object.entries(clientSideErrors).map(([key,value])=>{
    //   if (value !== '')
    //   {
    //     this.myTextInput.focus();
    //     console.log("s")
    //     console.log(this.myTextInput)
    //   }
    // })
    this.setState({
      clientSideErrors: clientSideErrors
    })
  }

  getDropdownValue(event, {value}) {
    this.setState({ features: value });
  }

  handleCancel() {
    this.props.history.push("/cars");
  }

  getPrimaryImage(primary_image){
    this.setState({ primary_image: primary_image })
  }

  getImages(images){
    this.setState({ images: images })
  }

  renderModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.showModal === true) {
      let car = this.state.modalCar;
      const image_gallery_array = [];
      image_gallery_array.push({ original: `${this.state.primary_image.data}`, thumbnail: `${this.state.primary_image.data}` });

      {this.state.images.map(function(image, index) {
        image_gallery_array.push({ original: `${image.data}`, thumbnail: `${image.data}` });
      })}
      return (
        <div>
          <Modal.Header closeButton>
            <Modal.Title id='car-modal-title'>
              {this.state.make} {this.state.model} - {this.state.engine_size}l ({this.state.year})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className='rounded'>
              <Row>
                <Col xs={12} sm={12} md={12} lg={7}>
                  <div className='card-image'>
                  <ImageGallery items={image_gallery_array} />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5}>
                  <Row>
                    <Col className='car-modal-details' xs={12} sm={12} md={6} lg={4}>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='pound-sign' size='sm' /> <p className='car-modal-details-value'>{this.state.price}</p><br/>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='car' size='sm' /><p className='car-modal-details-value'> {this.state.body_type}</p><br/>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='gas-pump' size='sm' /><p className='car-modal-details-value'> {this.state.fuel_type}</p>
                    </Col>
                    <Col className='car-modal-details' xs={12} sm={12} md={6} lg={4}>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='tachometer-alt' size='sm' /> <p className='car-modal-details-value'>{this.state.mileage} miles</p><br/>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='palette' size='sm' /><p className='car-modal-details-value'> {this.state.colour}</p><br/>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='calendar-alt' size='sm' /><p className='car-modal-details-value'> {this.state.year}</p>
                    </Col>
                    <Col className='car-modal-details' xs={12} sm={12} md={6} lg={4}>
                    <Image className='car-modal-details-icon' src='../images/car-door.png' /><p className='car-modal-details-value'>{this.state.doors} doors</p><br/>
                    <Image className='car-modal-details-icon' src='../images/car-seat.png' /><p className='car-modal-details-value'>{this.state.seats} seats</p><br/>
                    </Col>
                  </Row>
                  <Card.Body className='text-center'>
                    <div className='ad-title m-auto'>
                      <h2>Features</h2>
                        <p className='car-modal-details-features'>
                          {this.state.features.map(function(feature, index) {
                            return <span key={index}>{ (index ? ' - ' : '') + feature }</span>;
                          })}
                        </p>
                      <p className='car-modal-description'>{this.state.description}</p>
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
        </div>
      );
    }
  }

  render() {
    let data;

    if (this.state.loading)
    {
      data =
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    }
    else
    {

    data =
      <div className='main-container'>
        <Container>
          <Header as='h1'>Upload Car</Header>
          <p>Please provide details for car upload, ensuring you provide as many details as possible. Any errors will return error messages beside each field.</p>
          <Form id="car-form" className="mt-5" onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Registration</Form.Label>
              <Form.Control type="text" ref={(name) => { this.myTextInput= name; }} name="registration" value={this.state.registration} onChange={this.handleChange} style={{ textTransform: 'uppercase'}} onBlur={this.clientSideValid} className="form-control" />
              {this.state.errors.registration && <Form.Label className='text-danger formError'>Registration {this.state.errors.registration}</Form.Label> }
              {this.state.clientSideErrors.registration.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.registration}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Make</Form.Label>
              <Form.Control type="text" ref={(input) => { this.myTextInput= input; }} name="make" value={this.state.make} onChange={this.handleChange} className="form-control" />
              {this.state.errors.make && <Form.Label className='text-danger formError'>Make {this.state.errors.make}</Form.Label> }
              {this.state.clientSideErrors.make.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.make}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Model</Form.Label>
              <Form.Control type="text" ref={(input) => { this.myTextInput= input; }} name="model" value={this.state.model} onChange={this.handleChange} className="form-control" />
              {this.state.errors.model && <Form.Label className='text-danger formError'>Model {this.state.errors.model}</Form.Label> }
              {this.state.clientSideErrors.model.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.model}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Year</Form.Label>
              <Form.Control type="number" ref="year" name="year" value={this.state.year} onChange={this.handleChange} onBlur={this.clientSideValid} className="form-control" />
              {this.state.errors.year && <Form.Label className='text-danger formError'>Year {this.state.errors.year}</Form.Label> }
              {this.state.clientSideErrors.year.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.year}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Colour</Form.Label>
              <Form.Control type="text" ref='colour' name="colour" value={this.state.colour} onChange={this.handleChange} className="form-control" />
              {this.state.errors.colour && <Form.Label className='text-danger formError'>Colour{this.state.errors.colour}</Form.Label> }
              {this.state.clientSideErrors.colour.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.colour}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Fuel Type</Form.Label>
              <Form.Control ref='fuel_type' as="select" value={this.state.fuel_type} name="fuel_type" onChange={this.handleChange}>
                <option>Petrol</option>
                <option>Diesel</option>
                <option>Hybrid</option>
                <option>Electric</option>
                <option>LPG</option>
              </Form.Control>
              {this.state.errors.fuel_type && <Form.Label className='text-danger formError'>Fuel type {this.state.errors.fuel_type}</Form.Label> }
              {this.state.clientSideErrors.fuel_type.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.fuel_type}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Body Type</Form.Label>
              <Form.Control ref='body_type' as="select" value={this.state.body_type} name="body_type" onChange={this.handleChange}>
                <option>Hatchback</option>
                <option>Estate</option>
                <option>Saloon</option>
                <option>Coupe</option>
                <option>SUV</option>
              </Form.Control>
              {this.state.errors.body_type && <Form.Label className='text-danger formError'>Body type {this.state.errors.body_type}</Form.Label> }
              {this.state.clientSideErrors.body_type.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.body_type}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Mileage</Form.Label>
              <Form.Control type="number" ref='mileage' name="mileage" value={this.state.mileage} onChange={this.handleChange} className="form-control" />
              {this.state.errors.mileage && <Form.Label className='text-danger formError'>Mileage {this.state.errors.mileage}</Form.Label> }
              {this.state.clientSideErrors.mileage.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.mileage}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Engine Size</Form.Label>
              <Form.Control type="number" ref='engine_size' step="0.1" name="engine_size" value={this.state.engine_size} onChange={this.handleEngineSizeChange} className="form-control" />
              {this.state.errors.engine_size && <Form.Label className='text-danger formError'>Engine size {this.state.errors.engine_size}</Form.Label> }
              {this.state.clientSideErrors.engine_size.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.engine_size}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Transmission</Form.Label>
              <Form.Control as="select" ref='transmission' value={this.state.transmission} name="transmission" onChange={this.handleChange}>
                <option>Manual</option>
                <option>Automatic</option>
              </Form.Control>
              {this.state.errors.transmission && <Form.Label className='text-danger formError'>Transmission {this.state.errors.transmission}</Form.Label> }
              {this.state.clientSideErrors.transmission.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.transmission}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Doors</Form.Label>
              <Form.Control as="select" ref='doors' type="number" value={this.state.doors} name="doors" onChange={this.handleChange}>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
              </Form.Control>
              {this.state.errors.doors && <Form.Label className='text-danger formError'>Doors {this.state.errors.doors}</Form.Label> }
              {this.state.clientSideErrors.doors.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.doors}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Seats</Form.Label>
              <Form.Control as="select" ref='seats' type="number" value={this.state.seats} name="seats" onChange={this.handleChange}>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
              </Form.Control>
              {this.state.errors.seats && <Form.Label className='text-danger formError'>Seats {this.state.errors.seats}</Form.Label> }
              {this.state.clientSideErrors.seats.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.seats}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Features</Form.Label>
              <Dropdown name="features" ref='features'
              fluid multiple clearable selection options={features} onChange={this.getDropdownValue}  />
              {this.state.errors.features && <Form.Label className='text-danger formError'>Features {this.state.errors.features}</Form.Label> }
              {this.state.clientSideErrors.features.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.features}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" name="description" ref='description' rows="5" value={this.state.description} onChange={this.handleChange} className="form-control" />
              {this.state.errors.description && <Form.Label className='text-danger formError'>Description {this.state.errors.description}</Form.Label> }
              {this.state.clientSideErrors.description.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.description}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <div className="ui right fluid labeled input">
                <Form.Label className="ui label">£</Form.Label>
                <Form.Control type="number" ref='price' name="price" value={this.state.price} onChange={this.handleChange} className="form-control" />
              </div>
              {this.state.errors.price && <Form.Label className='text-danger formError'>Price {this.state.errors.price}</Form.Label> }
              {this.state.clientSideErrors.price.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.price}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Primary Image</Form.Label>
              <div className="ui right fluid">
                <FileBase64
                  ref='primary_image'
                  accept=".jpg, .jpeg"
                  className="form-control"
                  onDone={ this.getPrimaryImage.bind(this) } />
              </div>
              {this.state.errors.primary_image && <Form.Label className='text-danger formError'>Primary Image {this.state.errors.primary_image}</Form.Label> }
              {this.state.clientSideErrors.primary_image.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.primary_image}</span>}
            </Form.Group>
            <div>
              <img style={{"width": "20%"}} alt={`${this.state.registration}`}  src={`${this.state.primary_image.base64}`} />
            </div>
            <Form.Group>
              <Form.Label>Secondary Images (Please upload 1-4 images)</Form.Label>
              <div className="ui right fluid">
                <FileBase64
                  ref='images'
                  multiple={ true }
                  accept=".jpg, .jpeg"
                  className="form-control"
                  onDone={ this.getImages.bind(this) } />
              </div>
              {this.state.errors.images && <Form.Label className='text-danger formError'>Images {this.state.errors.images}</Form.Label> }
              {this.state.clientSideErrors.images.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.images}</span>}
            </Form.Group>
            <div>
              {this.state.images.map((image, index) => {
                return (<img key={index} style={{"width": "20%"}} alt={`${this.state.registration}` + '-' + `${index}`} src={`${image.base64}`} />)
              })}
            </div>
            <Form.Group>
              <Form.Label>MOT</Form.Label>
              <div className="ui right fluid"> 
                <input
                  name="mot"
                  type="checkbox"
                  checked={this.state.mot}
                  onChange={this.handleMOTChange} />
              </div>
              {this.state.errors.mot && <Form.Label className='text-danger formError'>MOT {this.state.errors.mot}</Form.Label> }
              {this.state.clientSideErrors.mot.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.mot}</span>}
            </Form.Group>
            <Form.Group style={{ visibility: this.state.mot ? 'visible': 'hidden'}}>
              <Form.Label>MOT Date</Form.Label>
              <div className="ui right fluid"> 
                <input
                  ref="mot_date"
                  name="mot_date"
                  type="date"
                  onChange={this.handleChange} />
              </div>
              {this.state.errors.mot_date && <Form.Label className='text-danger formError'>MOT {this.state.errors.mot_date}</Form.Label> }
              {this.state.clientSideErrors.mot_date.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.mot_date}</span>}
            </Form.Group>

            <ButtonToolbar>
              <Button variant="danger" type="button" onClick={this.handleModalOpen} className={`ui dark-red button ${this.state.isPreviewReady ? '' : 'disabled'}`}>Preview</Button>
              <Button variant="success" type="submit" onClick={this.handleSubmit} className={`ui dark-red button`}>Create</Button>
              <Button variant="danger" type="button" onClick={this.handleCancel} className="ui dark-red button">Cancel</Button>
            </ButtonToolbar>
          </Form>
        </Container>
        <Modal show={this.state.showModal}
               onHide={this.handleModalClose}
               size="lg"
               centered>
          {this.renderModal()}
        </Modal>
      </div>
  }
  return (
    <div className='main-container'>
    {data}
    </div>

  );
  }
}

export default CarAdd;
