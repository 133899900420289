import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Spinner, Modal, Pagination, Accordion} from 'react-bootstrap';
import { Button, Image, Label, Segment } from 'semantic-ui-react';
import Slider, { Range } from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import "semantic-ui-css/semantic.min.css";
import ImageGallery from 'react-image-gallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Truncate from 'react-truncate';
import moment from 'moment';
import paginate from 'paginate-array';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faInfo, faQuestion, faCar, faTachometerAlt, faTrash, faEdit, faPalette, faGasPump, faCalendarAlt, faComment} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

library.add(faPoundSign, faInfo, faQuestion, faCar, faFacebookF, faTrash, faEdit, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faComment);

const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
    <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class CarSearch extends Component {
  constructor() {
    super();
    const createSliderWithTooltip = Slider.createSliderWithTooltip;
    const Range = createSliderWithTooltip(Slider.Range);
    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(20),( val, index) => year - index);
    this.state = {
      make: '',
      makes: [],
      model: '',
      models: [],
      colour: '',
      cars: [],
      errors: {},
      modalCar: {},
      makeModelList: [],
      loading: true,
      years: years,
      minPrice: 0,
      maxPrice: 0,
      minPriceSelected: 0,
      maxPriceSelected: 0,
      minYear: 0,
      maxYear: 0,
      minYearSelected: 0,
      maxYearSelected: 0,
      features: [],
      selectedFeatures: [],
      showModal: false,
      size: 4,
      page: 1,
      sort: '',
      currPage: null,
      search: false,
      open: true,
      enquiryName: '',
      enquiryTelephone: '',
      enquiryEmail: '',
      enquiryMessage: '',
      enquiryRegistration: '',
      enquiryMessageSource: 'car',
      postedToFacebook: false
  };

    this.goToPage = this.goToPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.generateFormValues = this.generateFormValues.bind(this);
    this.postToFacebook = this.postToFacebook.bind(this);
    this.handleEnquiryChange = this.handleEnquiryChange.bind(this);
    this.handleEnquiryModalOpen = this.handleEnquiryModalOpen.bind(this);
    this.handleEnquiryModalClose = this.handleEnquiryModalClose.bind(this);
    this.handleEnquirySubmit = this.handleEnquirySubmit.bind(this);
  }

  componentDidMount() {

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars`})
      .then(response => {
        this.setState({
          cars: response.data
        });
        this.generateFormValues(this.state.cars);
        this.generatePagination(this.state);
      })
      .catch(error => console.log('error', error));
  }

  resize() {
      this.setState({mobileView: window.innerWidth >= 760});
  }

  generatePagination = (state) => {
    const { page, size } = this.state;

    const currPage = paginate(this.state.cars, page, size);
    let active = currPage;
    let items = [];
    let resetpage = 1;
    let loading = false;
    for (let number = 1; number <= currPage.totalPages; number++) {
      items.push(
        <Pagination.Item key={number} data-index={number} active={number === active} onClick={e => { this.goToPage(e)}}>
          {number}
        </Pagination.Item>,
      );
    }
    this.setState({
      ...this.state,
      currPage,
      items,
      page,
      loading,
    })
  }

  generateFormValues = (cars) => {
    let makeModelArray = [];
    let makeArray = [];

    let minPrice = this.state.minPrice;
    let maxPrice = this.state.maxPrice;
    let minYear = this.state.minYear;
    let maxYear = this.state.maxYear;
    let minPriceSelected = this.state.minPriceSelected;
    let maxPriceSelected = this.state.maxPriceSelected;
    let minYearSelected = this.state.minYearSelected;
    let maxYearSelected = this.state.maxYearSelected;

    makeArray.push(<option key={1} value=''>Please Select</option>);

    {cars.map((car, index) => {

      car.features.map((feature) => {
        if(this.state.features.indexOf(feature) < 0){
          this.setState((state) => ({
            features: state.features.concat([feature])
          }))
        }
      });
      if (parseFloat(car.price) >= parseFloat(maxPrice)){
        maxPrice = maxPriceSelected = Math.ceil((parseFloat(car.price + 1000))/100)*100;
      }
      if ((parseFloat(car.price) <= parseFloat(minPrice)) || (parseFloat(minPrice) === 0)){
        minPrice = minPriceSelected = Math.ceil((parseFloat(car.price - 100))/100)*100;
      }
      if (parseFloat(car.year) >= parseFloat(maxYear)){
        maxYear = maxYearSelected = parseInt(car.year + 1);
      }
      if ((parseFloat(car.year) <= parseFloat(minYear)) || (parseFloat(minYear) === 0)){
        minYear = minYearSelected = parseInt(car.year - 1);
      }

      let make = makeModelArray.filter(make_model => (make_model.make === car.make))
      let model = makeModelArray.filter(make_model => (make_model.models === car.model))
      if( make === undefined || make.length === 0) {
        makeArray.push(<option key={car.make} value={car.make}>{car.make}</option>);
        makeModelArray.push({ make: `${car.make}`, models: [`${car.model}`] });
      }
      else if ( model === undefined || model.length === 0) {
        for (var i=0; i < makeModelArray.length; i++) {
          let model_1 = makeModelArray[i].models.filter(model => (model === car.model));
          if (makeModelArray[i].make === car.make && model_1.length === 0){
            makeModelArray[i].models.push(car.model);
            }
          else {
          }
        }
      }
    })}
    this.setState({
      makeModelList: makeModelArray,
      makes: makeArray,
      priceMarks: { minYear: minYear, maxYear: maxYear },
      yearMarks: { minPrice: minPrice, maxPrice: maxPrice },
      minPrice: minPrice,
      maxPrice: maxPrice,
      minYear: minYear,
      maxYear: maxYear,
      minPriceSelected: minPriceSelected,
      maxPriceSelected: maxPriceSelected,
      minYearSelected: minYearSelected,
      maxYearSelected: maxYearSelected,
      loading: false
    });
  }

  postToFacebook = (car) => {

    let token = "Bearer " + localStorage.getItem('jwt');
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/${car.id}/facebook`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: {'id': car.id}})
      .then((response) => {
        this.setState({ postedToFacebook: true });

      })
      .catch((error) => {
        this.setState({ errors:error });
        console.log(error);
      });
  }

  handleEnquiryModalOpen = (car) => {
    this.setState({
      enquiryRegistration: car.registration,
      enquiryCarID: car.id,
      modalCar: car,
      showEnquiryModal: true
    })
  }

  handleEnquiryModalClose(){
    this.setState({ showEnquiryModal: false });
  }

  handleEnquirySubmit(event){
    var form_data = {
                      'name': this.state.enquiryName,
                      'email': this.state.enquiryEmail,
                      'telephone': this.state.enquiryTelephone,
                      'message_text': this.state.enquiryMessage,
                      'registration': this.state.enquiryRegistration,
                      'source_id': this.state.enquiryCarID,
                      'source': 'Cars'
                    }
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem('jwt');
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/messages`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: form_data})
      .then((response) => {
        this.setState({ showEnquiryModal: false });
      })
      .catch(error => this.setState({ errors: error.response.data.error }));
  }

  handleEnquiryChange(event) {

    event.preventDefault();
    this.setState(
      { [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value }
    );
  }

  renderEnquiryModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.showEnquiryModal === true) {
      const car = this.state.modalCar;
      return (
        <div>
          <Modal.Header closeButton>
            <Modal.Title id='enquiry-modal-title'>
              Enquire about <b>{car.make} {car.model} - {car.engine_size}l ({car.year})</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className='rounded' style={{border:'none'}}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Form id='contact-form'>
                    <Form.Group controlId='contactForm.ControlInput1'>
                      <Form.Control type='name' name='enquiryName' placeholder='Name' onChange={this.handleEnquiryChange} />
                      {this.state.errors.name && <Form.Label className='text-danger formError'>Name {this.state.errors.name}</Form.Label> }
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlInput2'>
                      <Form.Control type='email' name='enquiryEmail' placeholder='Email' onChange={this.handleEnquiryChange}/>
                      {this.state.errors.email && <Form.Label className='text-danger formError'>Email {this.state.errors.email}</Form.Label> }
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='telephone' name='enquiryTelephone' placeholder='Mobile/Telephone' onChange={this.handleEnquiryChange} />
                      {this.state.errors.telephone && <Form.Label className='text-danger formError'>Telephone {this.state.errors.telephone}</Form.Label> }
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Form.Control as='textarea' rows='5' name='enquiryMessage' placeholder='Message' onChange={this.handleEnquiryChange} />
                      {this.state.errors.text_message && <Form.Label className='text-danger formError'>Message {this.state.errors.text_message}</Form.Label> }
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Button variant="danger" type="button" onClick={()=>this.handleClear()} className="ui dark-red button">Clear</Button>
                      <Button variant="success" style={{float:'right'}} type="submit" onClick={this.handleEnquirySubmit} className={`ui dark-red button`}>Submit</Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
        </div>
      );
    }
  }

  handleDelete = (id) => {
    let token = "Bearer " + localStorage.getItem("jwt");
    this.setState({
      loading: true
    });
    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/${id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then(() => {
        axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars`})
          .then(response => {
            this.setState({
              cars: response.data
            });
            this.generateFormValues(this.state.cars);
            this.generatePagination(this.state);
          })
          .catch(error => console.log('error', error));
      })
      .catch(error => console.log('error', error));
  }

  handleMakeChange = (event) => {
    let models = [];
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index]
    var option =  optionElement.getAttribute('value');
    if (option === ''){
      this.setState({
        model: '',
        models: []
      })
    }
    this.state.makeModelList.map((make_model, index) => {
      if (option === make_model.make){
        make_model.models.map((model, index) => {
          models.push(<option key={model} value={model}>{model}</option>);
        })
        this.setState({
          models: models
        })
      }
    })
  }

  handlePriceChange = (value) => {
    this.setState({
      minPriceSelected: value[0],
      maxPriceSelected: value[1]
    }, function () {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/search/`,
        data: {
          make: this.state.make,
          model: this.state.model,
          year: this.state.year,
          colour: this.state.colour,
          min_price: this.state.minPriceSelected,
          max_price: this.state.maxPriceSelected,
          min_year: this.state.minYearSelected,
          max_year: this.state.maxYearSelected,
          features: this.state.selectedFeatures,
          sort: this.state.sort
        }
      })
      .then(response => {
        this.setState({
          cars: response.data,
          loading: true,
          page: 1})
        this.generatePagination(this.state);
      })
      .catch(error => console.log('error', error));
    })
  }

  handleYearChange = (value) => {
    this.setState({
      minYearSelected: value[0],
      maxYearSelected: value[1]
    }, function () {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/search/`,
        data: {
          make: this.state.make,
          model: this.state.model,
          year: this.state.year,
          colour: this.state.colour,
          min_price: this.state.minPriceSelected,
          max_price: this.state.maxPriceSelected,
          min_year: this.state.minYearSelected,
          max_year: this.state.maxYearSelected,
          features: this.state.selectedFeatures,
          sort: this.state.sort
        }
      })
      .then(response => {
        this.setState({
          cars: response.data,
          loading: true,
          page: 1})
        this.generatePagination(this.state);
      })
      .catch(error => console.log('error', error));
    })
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value}, function () {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/search/`,
        data: {
          make: this.state.make,
          model: this.state.model,
          year: this.state.year,
          colour: this.state.colour,
          min_price: this.state.minPriceSelected,
          max_price: this.state.maxPriceSelected,
          min_year: this.state.minYearSelected,
          max_year: this.state.maxYearSelected,
          features: this.state.selectedFeatures,
          sort: this.state.sort
        }
      })
      .then(response => {
        this.setState({
          cars: response.data,
          loading: true,
          page: 1,
          search: true})
        this.generatePagination(this.state);
      })
      .catch(error => console.log('error', error));
    })

    if(event.target.name === 'make')
    {
      this.handleMakeChange(event);
    }
    if(event.target.name === 'feature')
    {
      let feature = event.target.value;
      let checked = event.target.checked;
      if (checked === true){
        this.setState((state) => ({
          selectedFeatures: this.state.selectedFeatures.concat([feature])
        }))
      }
      else if (checked === false) {
        let features = [...this.state.selectedFeatures]
        let index = features.indexOf(feature)
        features.splice(index, 1);
        this.setState({selectedFeatures: features});
      }
    }
  };

  goToPage = (event) => {
    const { currPage, page, size, cars } = this.state;
    const newPage = event.target.getAttribute("data-index");
    const newCurrPage = paginate(cars, newPage, size);

    this.setState({
      ...this.state,
      page: newPage,
      currPage: newCurrPage
    });
  }

  handleSearchBarClick = () => {
    this.setState( (prevState: { open: any; }) => ({
      open: !prevState.open
    }))
  }

  render() {
    const {open} = this.state
    const { page, size, currPage, minPrice, maxPrice, minYear, maxYear } = this.state;
    let priceMarks = { [minPrice]: minPrice, [maxPrice]: maxPrice}
    let yearMarks = { [minYear]: minYear, [maxYear]: maxYear}
    let data;

    if (this.state.loading)
    {
      data =
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    }
    else
    {

    data =
      <div>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h1 className='page-header'>Cars For Sale</h1>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3' xs={12} sm={12} md={4} lg={3}>
              <Accordion {...(this.state.mobileView ? { defaultActiveKey: '0' } : {})}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Search Options
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Form.Group controlId='formMake'>
                        <Form.Label>Make</Form.Label>
                        <Form.Control name='make' as='select' placeholder='Make' value={this.state.make} onChange={e => { this.handleChange(e)}} >
                          { this.state.makes }
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId='formModel'>
                        <Form.Label>Model</Form.Label>
                        <Form.Control name='model' as='select' value={this.state.model} onChange={this.handleChange} >
                          <option key='0' value=''>Please select</option>
                          { this.state.models }
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId='formColour'>
                        <Form.Label>Colour</Form.Label>
                        <Form.Control name='colour' as='select' value={this.state.colour} onChange={this.handleChange} >
                          <option key='0' value=''>Please select</option>
                          <option>Blue</option>
                          <option>Black</option>
                          <option>Green</option>
                          <option>Silver</option>
                          <option>White</option>
                          <option>Brown</option>
                          <option>Red</option>
                          <option>Yellow</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId='formColour'>
                        <Form.Label>Year</Form.Label>
                        <div style={{margin: 10}}>
                          <Range min={this.state.minYear} max={this.state.maxYear} defaultValue={[this.state.minYearSelected, this.state.maxYearSelected]} marks={yearMarks} handle={handle} tipFormatter={value => `${value}%`} onAfterChange={this.handleYearChange} />
                        </div>
                        <br/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Price (£)</Form.Label>
                        <div style={{margin: 10}}>
                          <Range min={this.state.minPrice} max={this.state.maxPrice} step={50} defaultValue={[this.state.minPriceSelected, this.state.maxPriceSelected]} marks={priceMarks} handle={handle} tipFormatter={value => `${value}%`} onAfterChange={this.handlePriceChange} />
                        </div>
                        <br/>
                      </Form.Group>
                      {/* <Form.Group controlId='formColour'>
                        <Form.Label>Features</Form.Label>
                          {this.state.features.map((feature, index) => {
                            return <Form.Check key={feature} name='feature' value={`${feature}`} id={`${feature}`} label={`${feature}`} checked={this.state.selectedFeatures.includes(feature) ? true : false} onChange={event => { this.handleChange(event)}} />;
                          })}
                      </Form.Group> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            <br />
            <br />
            <br />
            <Col xs={12} sm={12} md={8} lg={9}>
              {currPage &&
                <div>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={9}>
                      <Pagination>
                        <Pagination.Item data-index={1} onClick={this.goToPage}>First</Pagination.Item>
                        {this.state.items}
                        <Pagination.Item data-index={this.state.currPage.totalPages} onClick={this.goToPage}>Last</Pagination.Item>
                      </Pagination>
                    </Col>
                      {/*
                    <Col xs={12} sm={0} md={3} lg={3}> <span style={{padding:'0.5rem 0.5rem', width:'auto'}}>{this.state.currPage.total + " records"}</span>
                    </Col> */}
                    <Col xs={6} sm={6} md={6} lg={3}>
                      <Form.Group controlId='formColour'>
                        <Form.Control style={{float:'right'}} name='sort' as='select' value={this.state.sort} onChange={this.handleChange} >
                          <option key='0' value=''>Sort By...</option>
                          <option value='price'>Price (ascending)</option>
                          <option value='price +'>Price (descending)</option>
                          <option value='year'>Year (ascending)</option>
                          <option value='year +'>Year (descending)</option>
                          <option value='mileage'>Mileage (ascending)</option>
                          <option value='mileage +'>Mileage (descending)</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                      {currPage.data.length ?
                        currPage.data.map((car, index) => {
                          let last_facebook_post
                          if (car.facebook_posted_date) {
                            last_facebook_post = moment.duration(moment(new Date()).diff(car.facebook_posted_date)).asDays();
                          }
                          else {
                            last_facebook_post = 100;
                          }
                          const image_gallery_array = [];
                          image_gallery_array.push({ original: `${car.primary_image}`, thumbnail: `${car.primary_image}` });

                          {car.images.map(function(image, index) {
                            image_gallery_array.push({ original: `${image}`, thumbnail: `${image}` });
                          })}
                        return(
                          <Col className='mb-5' xs={12} sm={12} md={12} lg={12} key={index}>
                            <Card>
                              <div style={{ visibility: (car.mot && moment(car.mot_date).isAfter(moment(), "day")) ? 'visible': 'hidden'}} className="ribbon ribbon-top-left"><span>Full MOT</span></div>
                              <Card.Body>
                                <Label style={{backgroundColor: '#B21606', color:'#ffffff', marginLeft: '5px'}}as='a' ribbon='right'>
                                  <h3>£ {car.price.toLocaleString()}</h3>
                                </Label>
                                <Row className="car-title-row">
                                  <Col className='mb-4' xs={12} sm={12} md={12} lg={9} id={car.id}>
                                    <Card.Title><h2>{car.make} {car.model} ({car.year})</h2></Card.Title>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={7}>
                                    <Row>
                                      <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                                        <FontAwesomeIcon title='Body Type' className='car-modal-details-icon' icon='car' size='lg' /><br/><p className='car-modal-details-value'> {car.body_type}
                                        </p><br/>
                                      </Col>
                                      <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                                        <FontAwesomeIcon title='Petrol Type' className='car-modal-details-icon' icon='gas-pump' size='lg' /><br/><p className='car-modal-details-value'> {car.fuel_type}</p><br/>
                                      </Col>
                                      <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                                        <Image title='No. Seats' className='car-modal-details-icon' src='../images/car-seat.png' /><br/><p className='car-modal-details-value'>{car.seats} seats</p><br/>
                                      </Col>
                                      <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                                        <FontAwesomeIcon title='Mileage' className='car-modal-details-icon' icon='tachometer-alt' size='lg' /><br/><p style={{textTransform:'lowercase'}}className='car-modal-details-value'>{car.mileage.toLocaleString()} miles</p><br/>
                                      </Col>
                                      <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                                        <FontAwesomeIcon title='Colour' className='car-modal-details-icon' icon='palette' size='lg' /><br/><p className='car-modal-details-value'> {car.colour}</p><br/>
                                      </Col>
                                      <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                                        <FontAwesomeIcon title='Year' className='car-modal-details-icon' icon='calendar-alt' size='lg' /><br/><p className='car-modal-details-value'> {car.year}</p><br/>
                                      </Col>
                                      <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                                        <Image title='No. Doors' className='car-modal-details-icon' src='../images/car-door.png' /><br/><p className='car-modal-details-value'>{car.doors} door</p><br/>
                                      </Col>
                                      { (car.mot && moment(car.mot_date).isAfter(moment(), "day"))  && ( 
                                        <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                                          <Image title='MOT Valid Til' className='car-modal-details-icon' src='../images/mot.png' /><br/>
                                          <p className='car-modal-details-value'> {moment(car.mot_date).format('MMM YYYY') }</p><br/>
                                        </Col>
                                      )}
                                    </Row>
                                    <Card.Body className='text-center'>
                                      <div className='ad-title m-auto'>
                                          <p className='car-modal-details-features'>
                                            {car.features.map(function(feature, index) {
                                              return <span className='car-model-features' key={index}>{ (index ? ' - ' : '')} {(index % 2 === 0 ? <span><b>{feature}</b></span> : <span>{feature}</span>)}</span>;
                                            })}
                                          </p>
                                        <p className='car-modal-description'>
                                          <Truncate lines={2} ellipsis={<span>...</span>}>
                                            {car.description}
                                          </Truncate>
                                        </p>
                                      </div>
                                    </Card.Body>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={5}>
                                    <div className='card-image'>
                                      <div>
                                        <ImageGallery items={image_gallery_array} />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              {/*<Row>
                                <Col className='mb-2' xs={12} sm={12} md={12} lg={12} key={index}>
                                  <Card.Title><h2>{car.make} {car.model} ({car.year})</h2></Card.Title>
                                </Col>
                                <Col className='mb-5' xs={12} sm={12} md={5} lg={5} key={index}>
                                  <Card.Img variant='top' key={index} src={`${car.primary_image}`} />
                                  <Card.Text>
                                  </Card.Text>
                                  <Card.Text className='text-muted'>£{car.price.toLocaleString()}</Card.Text>
                                </Col>
                                <Col className='mb-5' xs={12} sm={12} md={7} lg={7} key={index}>
                                  <Truncate lines={1} ellipsis={<span>...</span>}>
                                    {car.description}
                                  </Truncate>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                  {car.features.map(function(feature, index) {
                                    return <span className='car-model-features' key={index}>{ (index ? ' - ' : '') + feature }</span>;
                                  })}
                                </Col>
                              </Row>*/}
                              </Card.Body>
                              <Card.Footer>
                                <Button href={`/cars/${car.id}`} className='ui dark-red button'>
                                  <span>Info</span> <FontAwesomeIcon icon='info' size='1x' style={{display:'inline-block', marginLeft:'5px'}}/>
                                </Button>
                                <Button onClick={()=>this.handleEnquiryModalOpen(car)} className='ui dark-red button' style={{ float:'right'}}>
                                <span>Enquire</span> <FontAwesomeIcon icon='comment' size='1x' style={{display:'inline-block', marginLeft:'5px'}}/>
                                </Button>
                                { ( localStorage.getItem('jwt') && last_facebook_post > 3 ) &&
                                  <Button onClick={()=>this.postToFacebook(car)} className='ui dark-red button'>
                                    <span className='button-text'>Post</span> <FontAwesomeIcon  icon={['fab', 'facebook-f']} size='1x' style={{display:'inline-block', marginLeft:'5px'}}/>
                                  </Button>
                                }
                                { (localStorage.getItem('jwt')) &&
                                  <Button href={`/cars/${car.id}/edit`} className='ui dark-red button'>
                                    <span className='button-text'>Edit</span> <FontAwesomeIcon  icon='edit' size='1x' style={{display:'inline-block', marginLeft:'5px'}}/>
                                  </Button>
                                }
                                { (localStorage.getItem('jwt')) &&
                                  <Button onClick={()=>this.handleDelete(car.id)} className='ui dark-red button'>
                                    <span className='button-text'>Delete</span> <FontAwesomeIcon  icon='trash' size='1x' style={{display:'inline-block', marginLeft:'5px'}}/>
                                  </Button>
                                }
                              </Card.Footer>
                            </Card>
                          </Col>
                        )
                      })
                      :
                        <Col className='mt-5 mb-5' xs={12} sm={12} md={12} lg={12}>
                          <p style={{textAlign:'center',fontSize:'1.4em', width:'90%',margin:'0 auto'}}>We currently dont have any cars which match your search or may not currently have any cars in stock. Please select another option</p>
                        </Col>
                    }
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                      </Col>
                      <Col xs={12} sm={6} md={3} lg={3}>
                      </Col>
                      <Col xs={12} sm={6} md={3} lg={3}>
                        <Pagination style={{float:'right'}}>
                          <Pagination.Item data-index={1} onClick={this.goToPage}>First</Pagination.Item>
                          {this.state.items}
                          <Pagination.Item data-index={this.state.currPage.totalPages} onClick={this.goToPage}>Last</Pagination.Item>
                        </Pagination>
                      </Col>
                    </Row>
                  </div>
                }
            </Col>
          </Row>
        </Container>
      </div>
    }
    return (
      <div className='main-container'>
      {data}
      <Modal show={this.state.showEnquiryModal}
             onHide={this.handleEnquiryModalClose}
             size="xl"
             centered>
        {this.renderEnquiryModal()}
      </Modal>
      </div>

    );
  }
}

export default CarSearch;
