import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Spinner, Modal, Pagination, Accordion} from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { Button, Image, Label} from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Truncate from 'react-truncate';
import paginate from 'paginate-array';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt);

class CarInfo extends Component {
  constructor() {
    super();
    this.state = {
      car: {},
      modalCar: {},
      loading: true,
      response: '',
      enquiryName: '',
      enquiryTelephone: '',
      enquiryEmail: '',
      enquiryMessage: '',
      enquiryRegistration: '',
      enquiryMessageSource: 'car',
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.displayImages = this.displayImages.bind(this);
    this.postToFacebook = this.postToFacebook.bind(this);
    this.handleEnquiryChange = this.handleEnquiryChange.bind(this);
    this.handleEnquiryModalOpen = this.handleEnquiryModalOpen.bind(this);
    this.handleEnquiryModalClose = this.handleEnquiryModalClose.bind(this);
    this.handleEnquirySubmit = this.handleEnquirySubmit.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/${this.props.match.params.id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then((response) => {
        this.setState({
          car: response.data,
          loading: false
        })
      })
      .catch(error => console.log('error', error));
  }

  handleDelete() {
    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/${this.props.match.params.id}`})
      .then(() => {
        this.props.history.push("/cars")
      })
      .catch(error => console.log('error', error));
  }

  handleCardClick = (id) => {
   this.props.history.push(`/api/cars/${id}`)
  }

  displayImages() {
    const image_gallery_array = [];
    image_gallery_array.push({ original: `${this.state.car.primary_image}`, thumbnail: `${this.state.car.primary_image}` });
    this.state.car.images.map((car_image, index) => {
      image_gallery_array.push({ original: `${car_image}`, thumbnail: `${car_image}` });
    })
    return (
      <ImageGallery
       items={image_gallery_array} />
    );
  }

  postToFacebook = (car) => {

    let token = "Bearer " + localStorage.getItem('jwt');
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/${car.id}/facebook`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: {'id': car.id}})
      .then((response) => {
        this.setState({ postedToFacebook: true });

      })
      .catch((error) => {
        this.setState({ errors:error });
        console.log(error);
      });
  }

  handleEnquiryModalOpen = (car) => {
    this.setState({
      enquiryRegistration: car.registration,
      enquiryCarID: car.id,
      modalCar: car,
      showEnquiryModal: true
    })
  }

  handleEnquiryModalClose(){
    this.setState({ showEnquiryModal: false });
  }

  handleEnquirySubmit(event){
    var form_data = {
                      'name': this.state.enquiryName,
                      'email': this.state.enquiryEmail,
                      'telephone': this.state.enquiryTelephone,
                      'message_text': this.state.enquiryMessage,
                      'registration': this.state.enquiryRegistration,
                      'source_id': this.state.enquiryCarID,
                      'source': 'Cars'
                    }
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem('jwt');
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/messages`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: form_data})
      .then((response) => {
        this.setState({ showEnquiryModal: false });
      })
      .catch(error => this.setState({ errors: error.response.data.error }));
  }

  handleEnquiryChange(event) {

    event.preventDefault();
    this.setState(
      { [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value }
    );
  }

  renderEnquiryModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.showEnquiryModal === true) {
      const car = this.state.modalCar;
      return (
        <div>
          <Modal.Header closeButton>
            <Modal.Title id='enquiry-modal-title'>
              Enquire about <b>{car.make} {car.model} - {car.engine_size}l ({car.year})</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className='rounded' style={{border:'none'}}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Form id='contact-form'>
                    <Form.Group controlId='contactForm.ControlInput1'>
                      <Form.Control type='name' name='enquiryName' placeholder='Name' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlInput2'>
                      <Form.Control type='email' name='enquiryEmail' placeholder='Email' onChange={this.handleEnquiryChange}/>
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='telephone' name='enquiryTelephone' placeholder='Mobile/Telephone' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Form.Control as='textarea' rows='5' name='enquiryMessage' placeholder='Message' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Button variant="danger" type="button" onClick={()=>this.handleClear()} className="ui dark-red button">Clear</Button>
                      <Button variant="success" style={{float:'right'}} type="submit" onClick={this.handleEnquirySubmit} className={`ui dark-red button`}>Submit</Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
        </div>
      );
    }
  }

  render() {
    let data;
    if (this.state.loading)
    {
      data =
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    }
    else
    {
      let last_facebook_post
      if (this.state.car.facebook_posted_date) {
        last_facebook_post = moment.duration(moment(new Date()).diff(this.state.car.facebook_posted_date)).asDays();
      }
      else {
        last_facebook_post = 100;
      }
      const image_gallery_array = [];
      image_gallery_array.push({ original: `${this.state.car.primary_image}`, thumbnail: `${this.state.car.primary_image}` });

      {this.state.car.images.map(function(image, index) {
        image_gallery_array.push({ original: `${image}`, thumbnail: `${image}` });
      })}
      data =
        <div>
          <Container>
            <Row id="ads"> 
              <Col className='mb-5' xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <div style={{ visibility: (this.state.car.mot && moment(this.state.car.mot_date).isAfter(moment(), "day")) ? 'visible': 'hidden'}} className="ribbon ribbon-top-left">
                    <span> Full MOT </span>
                  </div>
                  <Card.Body>
                    <Label style={{backgroundColor: '#B21606', color:'#ffffff', marginLeft: '5px'}} as='a' ribbon='right'>
                      <h3>£ {this.state.car.price.toLocaleString()}</h3>
                    </Label>
                    <Row className="car-title-row">
                      <Col className='mb-4' xs={12} sm={10} md={9} lg={9} id={this.state.car.id}>
                        <Card.Title><h2>{this.state.car.make} {this.state.car.model} ({this.state.car.year})</h2></Card.Title>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={12} md={12} lg={7}>
                        <Row>
                          <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                            <FontAwesomeIcon title='Body Type' className='car-info-modal-details-icon' icon='car' size='lg' /><br/><p className='car-modal-details-value'> {this.state.car.body_type}
                            </p><br/>
                          </Col>
                          <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                            <FontAwesomeIcon title='Petrol Type' className='car-info-modal-details-icon' icon='gas-pump' size='lg' /><br/><p className='car-modal-details-value'> {this.state.car.fuel_type}</p><br/>
                          </Col>
                          <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                            <Image className='car-info-modal-details-icon' title='No. Seats' src='../images/car-seat.png' /><br/><p className='car-modal-details-value'>{this.state.car.seats} seats</p><br/>
                          </Col>
                          <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                            <FontAwesomeIcon className='car-info-modal-details-icon' title='Mileage' icon='tachometer-alt' size='lg' /> <br/><p style={{textTransform:'lowercase'}}className='car-modal-details-value'>{this.state.car.mileage.toLocaleString()} miles</p><br/>
                          </Col>
                          <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                            <FontAwesomeIcon className='car-info-modal-details-icon' title='Colour' icon='palette' size='lg' /><br/><p className='car-modal-details-value'> {this.state.car.colour}</p><br/>
                          </Col>
                          <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                            <FontAwesomeIcon className='car-info-modal-details-icon' title='Year' icon='calendar-alt' size='lg' /><br/><p className='car-modal-details-value'> {this.state.car.year}</p><br/>
                          </Col>
                          <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                            <Image className='car-info-modal-details-icon' title='No. Doors' src='../images/car-door.png' /><br/><p className='car-modal-details-value'>{this.state.car.doors} door</p><br/>
                          </Col>
                          { (this.state.car.mot && moment(this.state.car.mot_date).isAfter(moment(), "day"))  && ( 
                            <Col className='car-modal-details' xs={4} sm={4} md={4} lg={4}>
                              <Image className='car-info-modal-details-icon' title='MOT Valid Til' src='../images/mot.png' /><br/>
                              <p className='car-modal-details-value'>{moment(this.state.car.mot_date).format('MMM YYYY') }</p>
                            </Col>
                          )}
                        </Row>
                        <Card.Body className='text-center'>
                          <div className='ad-title m-auto'>
                              <p className='car-modal-details-features'>
                                {this.state.car.features.map(function(feature, index) {
                                  return <span className='car-model-features' key={index}>{ (index ? ' - ' : '')} {(index % 2 === 0 ? <span><b>{feature}</b></span> : <span>{feature}</span>)}</span>;
                                })}
                              </p>
                            <p className='car-modal-description'>
                              <Truncate lines={2} ellipsis={<span>...</span>}>
                                {this.state.car.description}
                              </Truncate>
                            </p>
                          </div>
                        </Card.Body>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={5}>
                        <div className='card-image'>
                          <div>
                            <ImageGallery items={image_gallery_array} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <Button onClick={()=>this.handleEnquiryModalOpen(this.state.car)} title='Enquite' className='ui dark-red button' style={{ float:'right'}}>Enquire
                      <FontAwesomeIcon icon='comment' size='1x' title='Enquite' style={{display:'inline-block', marginLeft:'5px'}}/>
                    </Button>
                    { ( localStorage.getItem('jwt') && last_facebook_post > 3 ) &&
                      <Button onClick={()=>this.postToFacebook(this.state.car)} title='Post to Facebook' className='ui dark-red button'>
                        <span className='button-text'>Post</span> <FontAwesomeIcon title='Post to Facebook' icon={['fab', 'facebook-f']} size='1x' style={{display:'inline-block', marginLeft:'5px'}}/>
                      </Button>
                    }
                    { (localStorage.getItem('jwt')) &&
                      <Button href={`/cars/${this.state.car.id}/edit`} title='Edit' className='ui dark-red button'>
                        <span className='button-text'>Edit</span> <FontAwesomeIcon title='Edit' icon='edit' size='1x' style={{display:'inline-block', marginLeft:'5px'}}/>
                      </Button>
                    }
                    { (localStorage.getItem('jwt')) &&
                      <Button onClick={()=>this.handleDelete(this.state.car.id)} title='Delete' className='ui dark-red button'>
                         <span className='button-text'>Delete</span> <FontAwesomeIcon title='Delete' icon='trash' size='1x' style={{display:'inline-block', marginLeft:'5px'}}/>
                      </Button>
                    }
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
    }
    return (
      <div className='main-container'>
        {data}
        <Modal show={this.state.showEnquiryModal}
              onHide={this.handleEnquiryModalClose}
              size="xl"
              centered>
          {this.renderEnquiryModal()}
        </Modal>
      </div>
    );
    }
  }

export default CarInfo;
