import React from 'react';
import { Image, Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faMapMarketAlt, faPhone, faEnvelopeOpen, faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import {BrowserRouter as NavLink, Link} from 'react-router-dom'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';

library.add(faUser, faSignInAlt, faSignOutAlt, faFacebookF, faEnvelopeOpen );

class Footer extends React.Component {
  render() {
    return (
      <div id="myFooter" className="mt-5">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={8}>
              <h2 className="logo">
                <a href="/">
                  <Image src="/images/McElroy_Motors_Inverted.png" fluid />
                </a>
              </h2>
            </Col>
            <Col xs={6} sm={6} md={3} lg={2}>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/about">Used Cars</a></li>
                <li><a href="/services">Services & MOT</a></li>
                <li><a href="/contact">Contact Us</a></li>
              </ul>
            </Col>
            <Col xs={6} sm={6} md={3} lg={2}>
              <div className="social-networks">
                <a style={{color:'#B21606'}} href="tel:+447886597807"><FontAwesomeIcon size='xs' icon="phone" /></a>
                <a style={{color:'#B21606'}} href="mailto:info@mcelroymotors.com"><FontAwesomeIcon size='xs' icon="envelope-open" /></a>
                <a style={{color:'#B21606'}} href="https://www.facebook.com/mcelroymotors"><FontAwesomeIcon size='xs' icon={['fab', 'facebook-f']} /></a>
              </div>
              <Link to='/contact'>
                <Button style={{backgroundColor:'#B21606'}}  className="ui dark-red button">
                  Contact Us
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="footer-copyright">
          <p>© {(new Date().getFullYear())} Copyright  </p>
        </div>
      </div>
    );
  }
}

export default Footer;
