import React, { Component } from 'react';
import axios from 'axios';
import { Container, Col, Row, Image, Modal, Card, Form, Button } from 'react-bootstrap';

export class Services extends Component {
  constructor() {
    super();
    this.state = {
      showEnquiryModal: false,
      enquiryName: '',
      enquiryEmail: '',
      enquiryTelephone: '',
      enquiryMessage: ''
  };

    this.handleEnquiryModalOpen = this.handleEnquiryModalOpen.bind(this);
    this.handleEnquiryModalClose = this.handleEnquiryModalClose.bind(this);
    this.handleEnquiryChange = this.handleEnquiryChange.bind(this);
    this.handleEnquirySubmit = this.handleEnquirySubmit.bind(this);
  }


  handleEnquiryModalOpen = (topic, message) => {
    this.setState({
      showEnquiryModal: true,
      enquiryModalMessage: message,
      enquiryModalTopic: topic
    })
  }

  handleEnquiryModalClose(){
    this.setState({
      showEnquiryModal: false,
      enquiryModalMessage: '',
      enquiryModalTopic: ''
    });
  }



  handleEnquirySubmit(event){
    var form_data = {
                      'name': this.state.enquiryName,
                      'email': this.state.enquiryEmail,
                      'telephone': this.state.enquiryTelephone,
                      'message_text': this.state.enquiryMessage
                    }
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem('jwt');
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/messages`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: form_data})
      .then((response) => {
        this.setState({ showEnquiryModal: false });
      })
      .catch(error => this.setState({ errors: error.response.data.error }));
  }

  handleEnquiryChange(event) {

    event.preventDefault();
    this.setState(
      { [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value }
    );
  }

  renderEnquiryModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.showEnquiryModal === true) {
      return (
        <div>
          <Modal.Header closeButton>
            <Modal.Title id='enquiry-modal-title'>
              Enquire about {this.state.enquiryModalTopic}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className='rounded' style={{border:'none'}}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Form id='contact-form'>
                    <Form.Group controlId='contactForm.ControlInput1'>
                      <Form.Control type='name' name='enquiryName' placeholder='Name' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlInput2'>
                      <Form.Control type='email' name='enquiryEmail' placeholder='Email' onChange={this.handleEnquiryChange}/>
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='telephone' name='enquiryTelephone' placeholder='Mobile/Telephone' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Form.Control as='textarea' rows='5' name='enquiryMessage' value={this.state.enquiryModalMessage} placeholder='Message' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Button variant="danger" type="button" onClick={()=>this.handleClear()} className="ui dark-red button">Clear</Button>
                      <Button variant="success" style={{float:'right'}} type="submit" onClick={this.handleEnquirySubmit} className={`ui dark-red button`}>Submit</Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
        </div>
      );
    }
  }









  render() {
    return (
      <div>
        <Container>

          <Row>
            <Col className='mt-5 mb-5' xs={12} sm={12} md={6} lg={12}>
              <h1 classname='page-header' >Services & MOT</h1>
            </Col>
          </Row>

          <Row className='mb-5'>
            <Col className='mb-5' xs={12} sm={6} md={4} lg={4}>
              <Card style={{minHeight: '700px'}}>
                <Card.Header>
                  <h2>MOT</h2>
                </Card.Header>
                <Card.Body>
                  <Image className='service-image' src='/images/mot.png' style={{width: '80%'}} />
                  <Card.Text>
                     At McElroy Motors, we provide pre- MOT checks for any customer that requests one.<br/><br/>
                     To book and check availability, contact us now!
                  </Card.Text>
                 <Button style={{position: 'absolute', bottom: '30px'}} onClick={()=>this.handleEnquiryModalOpen('MOT','I want to book/know more about booking an MOT')} className='ui dark-red button'>Enquire</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-5' xs={12} sm={6} md={4} lg={4}>
              <Card style={{minHeight: '700px'}}>
                <Card.Header>
                  <h2>Bodywork</h2>
                </Card.Header>
                <Card.Body>
                  <Image className='service-image' src='https://mobil-hondapromo.com/wp-content/uploads/2019/02/Car-Bodywork-Repairs-696x696.jpg' />
                  <Card.Text>
                     We provide body work service on site to anything from fixing a small scratch on your bumper to
                     sourcing out a new door for your vehicle! <br/><br/>
                     Get in touch today for a price quote and availability
                  </Card.Text>
                 <Button style={{position: 'absolute', bottom: '30px'}} onClick={()=>this.handleEnquiryModalOpen('Bodywork','I want to book/know more about booking an appointment for bodywork.')} className='ui dark-red button'>Enquire</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-5' xs={12} sm={6} md={4} lg={4}>
              <Card style={{minHeight: '700px'}}>
                <Card.Header>
                  <h2>Diagnostics</h2>
                </Card.Header>
                <Card.Body>
                  <Image className='service-image' src='https://www.aamcocolorado.com/wp-content/uploads/2016/04/cardiagnostics.jpg' style={{width: '100%'}}  />
                  <Card.Text>
                    With our diagnostic equipment we can quickly find any problem that may be occurring in your car, before you may know it!
                    To ensure your car is working to the best it can be, <br/><br/>
                    Get in touch today for a quality diagnostic check on your vehicle
                  </Card.Text>
                  <Button style={{position: 'absolute', bottom: '30px'}} onClick={()=>this.handleEnquiryModalOpen('Diagnostics','I want to know more about the diagnostics services you provide.')} className='ui dark-red button'>Enquire</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-5' xs={12} sm={6} md={4} lg={4}>
              <Card style={{minHeight: '700px'}}>
                <Card.Header>
                  <h2>Battery replacement</h2>
                </Card.Header>
                <Card.Body>
                  <Image className='service-image' src='https://www.expertcarcareinc.com/images/car_battery2.jpeg'  style={{width: '95%'}}/>
                  <Card.Text>
                    We carry an extensive range of batteries here at McElroy which are made available at your
                    request and can be fitted while your car is being serviced.<br/><br/>
                    Reach out today to check availability
                  </Card.Text>
                  <Button style={{position: 'absolute', bottom: '30px'}} onClick={()=>this.handleEnquiryModalOpen('Battery Replacement','I want to know more about getting a replacement battery.')} className='ui dark-red button'>Enquire</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-5' xs={12} sm={6} md={4} lg={4}>
              <Card style={{minHeight: '700px'}}>
                <Card.Header>
                  <h2>Tyre Replacement</h2>
                </Card.Header>
                <Card.Body>
                  <Image className='service-image' src='https://res.cloudinary.com/intercars/image/upload/c_pad,h_400,w_400/v1/catalog/services/2u-78SL' />
                  <Card.Text>
                    At our site with stock and source a wide range of tyres available to be purchased and
                    fitted at your request.<br/><br/>
                    Contact us today to find tyres for you!
                  </Card.Text>
                  <Button style={{position: 'absolute', bottom: '30px'}} onClick={()=>this.handleEnquiryModalOpen('Tyre Replacement','I want to know more about getting replacement tyres.')} className='ui dark-red button'>Enquire</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-5' xs={12} sm={6} md={4} lg={4}>
              <Card style={{minHeight: '700px'}}>
                <Card.Header>
                  <h2>Brakes</h2>
                </Card.Header>
                <Card.Body>
                  <Image className='service-image' src='https://www.tyres-loughborough.co.uk/catalog/view/theme/agnv1/image/brake.jpg' style={{width: '95%'}} />
                  <Card.Text>
                    At McElroy Motors, we also source and fit new brake pads to vehicles to ensure your safe when driving in
                    any condition the weather may throw at you.<br/><br/>
                    Contact McElroy Motors today to book in for new brakes or part availability
                  </Card.Text>
                  <Button style={{position: 'absolute', bottom: '30px'}} onClick={()=>this.handleEnquiryModalOpen('Brakes','I want to book my car in for brake replacement.')} className='ui dark-red button'>Enquire</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal show={this.state.showEnquiryModal}
               onHide={this.handleEnquiryModalClose}
               size="xl"
               centered>
          {this.renderEnquiryModal()}
        </Modal>
      </div>
    );
  }
}

export default Services;
