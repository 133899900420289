import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Spinner} from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faEnvelope, faPhone, faSms, faMobile, faMobileAlt} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

library.add(faPoundSign, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faEnvelope, faPhone, faSms, faMobile, faMobileAlt);

class CustomerSearch extends Component {
  constructor() {
    super();
    this.state = {
      customers: [],
      forename: '',
      surname: '',
      address_1: '',
      address_2: '',
      town: '',
      post_code: '',
      county: '',
      telephone_number: '',
      mobile: '',
      email: '',
      loading: true,
      name: '',
      errors: []};
      this.handleChange = this.handleChange.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/`, headers: {'Authorization': token }, data: this.state})
      .then(response => {
        this.setState({
          customers: response.data,
          loading: false,
        });
      })
      .catch(error => console.log('error', error));
  }

  handleDelete = (id) => {
    let token = "Bearer " + localStorage.getItem("jwt");
    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then(() => {
        window.location.reload();
      })
      .catch(error => console.log('error', error));
  }


  handleChange(event) {
    event.preventDefault();
    this.setState({
      name: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (localStorage.getItem("jwt")){
      let token = "Bearer " + localStorage.getItem("jwt");
      axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/search`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: {name: this.state.name}})
        .then((response) => {
          console.log(response)
          this.setState({
            customers: response.data
          })
        })
        .catch(error => this.setState({ errors: error.response.data.error }));
    }
    else{
      this.props.history.push("/")
    }
  }

  render() {
    let data;
    if (this.state.loading)
    {
      data =
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    }
    else
    {

      data =
        <div>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h1 className='page-header'>Customer Database</h1>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form onClick={this.handleSubmit} >
                <Form.Group controlId='formMake'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control name='name' placeholder='Customer Name' value={this.state.make} onChange={e => { this.handleChange(e)}} >
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='formMake'>
                  <Button variant="success" type="submit" className="ui dark-red button">Search</Button>
                  <Button variant="danger" type="button" onClick={this.handleCancel} className="ui dark-red button">Cancel</Button>
                </Form.Group>
                </Form>
              </Col>
            </Row>

            <Row>
            {this.state.customers.map((customer, index) => {
              return(
                <Col className='mt-5' xs={12} sm={12} md={6} lg={6} key={index}>
                  <Card>
                    <Card.Body>
                      <Card.Title><p><b>{customer.forename} {customer.surname}</b></p></Card.Title>
                      <Card.Text>
                        <p>{customer.address_1}<br/>
                        {(customer.address_2 ? <span>{customer.address_2} <br /></span> : '')}
                        {customer.town}<br/>
                        {customer.post_code}<br/>
                        County {customer.county}</p>
                      </Card.Text>
                      <Card.Text>
                        <Row>
                          <Col xs={10} sm={10} md={9} lg={10}>
                            <p>{customer.telephone_number}</p>
                          </Col>
                          <Col xs={2} sm={2} md={3} lg={2}>
                            <a href={`tel:${customer.telephone_number}`}><FontAwesomeIcon icon='phone' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/></a>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={9} sm={9} md={8} lg={9}>
                            <p>{customer.mobile}</p>
                          </Col>
                          <Col xs={3} sm={3} md={4} lg={3}>
                            <span>
                              <a href={`sms:${customer.mobile}`}>
                                <FontAwesomeIcon icon='sms' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                              </a>
                              <a href={`tel:${customer.mobile}`}>
                                <FontAwesomeIcon icon='mobile-alt' size='lg' style={{display:'inline-block', float:'right', marginRight:'10px', color:'#B21606'}}/>
                              </a>
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={10} sm={10} md={9} lg={10}>
                            <p>{customer.email}</p>
                          </Col>
                          <Col xs={2} sm={2} md={3} lg={2}>
                            <a href={`mailto:${customer.email}`}>
                              <FontAwesomeIcon icon='envelope' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                            </a>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' href={`/customers/${customer.id}/edit`}>Edit</Button> }
                      { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' onClick={()=>this.handleDelete(customer.id)}>Delete</Button> }
                      { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' href={`/customers/${customer.id}`}>Details</Button> }
                    </Card.Footer>
                  </Card>
                </Col>
                  )
                })}
            </Row>
            <Row>
              <Col className='mt-5' xs={12} sm={12} md={12} lg={12}>
                <Button style={{float:'right'}} className='mb-4 ui dark-red button' href='/customers/new' variant="outline-primary" size="lg" block>
                  New Customer
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
    }
    return (
      <div className='main-container'>
      {data}
      </div>
    );
  }
}

export default CustomerSearch;
