import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Spinner, Table, Breadcrumb } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Truncate from 'react-truncate';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faTachometerAlt, faPalette, faTrash, faInfoCircle, faEdit, faGasPump, faCalendarAlt, faEnvelope, faPhone, faSms, faMobile, faMobileAlt} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faTachometerAlt, faPalette, faTrash, faInfoCircle, faEdit, faGasPump, faCalendarAlt, faEnvelope, faPhone, faSms, faMobile, faMobileAlt);


class CustomerInfo extends Component {
  constructor() {
    super();
    this.state = {
      cars: [],
      customer: {},
      customer_loading: true,
      cars_loading: true,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("jwt")){
      let token = "Bearer " + localStorage.getItem("jwt");

      axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
        .then((response) => {
          this.setState({
            customer: response.data,
            customer_loading: false
          })
        })
        .catch(error => console.log('error', error));

      axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.id}/customercars`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
        .then((response) => {
          this.setState({
            cars: response.data,
            cars_loading: false
          })
        })
        .catch(error => console.log('error', error));
    }
    else{
      this.props.history.push("/")
    }
  }

  handleDelete(car_id) {
    console.log(car_id)
    let token = "Bearer " + localStorage.getItem("jwt");
    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.id}/customercars/${car_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then(() => {
        window.location.reload();
      })
      .catch(error => console.log('error', error));
  }

  handleCardClick = (id) => {
   this.props.history.push(`/customers/${id}`)
  }

  render() {
    let data;
    if (this.state.customer_loading || this.state.cars_loading)
    {
      data =
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    }
    else
    {
      data = <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h1 className='page-header'>{this.state.customer.forename} {this.state.customer.surname}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item href={`/customers/search`}>
                Customers
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{this.state.customer.forename} {this.state.customer.surname}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} key={this.state.customer.id}>

            <Card>
              <Card.Body>
                <Card.Title><p><b>{this.state.customer.forename} {this.state.customer.surname}</b></p></Card.Title>
                <Card.Text>
                  <p>{this.state.customer.address_1}<br/>
                  {(this.state.customer.address_2 ? <span>{this.state.customer.address_2} <br/></span> : '')}
                  {this.state.customer.town}<br/>
                  {this.state.customer.post_code}<br/>
                  County {this.state.customer.county}</p>
                </Card.Text>
                <Card.Text>
                  <Row>
                    <Col xs={10} sm={10} md={9} lg={10}>
                      <p>{this.state.customer.telephone_number}</p>
                    </Col>
                    <Col xs={2} sm={2} md={3} lg={2}>
                      <a href={`tel:${this.state.customer.telephone_number}`}><FontAwesomeIcon icon='phone' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/></a>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={9} sm={9} md={8} lg={9}>
                      <p>{this.state.customer.mobile}</p>
                    </Col>
                    <Col xs={3} sm={3} md={4} lg={3}>
                      <span>
                        <a href={`sms:${this.state.customer.mobile}`}>
                          <FontAwesomeIcon icon='sms' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                        </a>
                        <a href={`tel:${this.state.customer.mobile}`}>
                          <FontAwesomeIcon icon='mobile-alt' size='lg' style={{display:'inline-block', float:'right', marginRight:'10px', color:'#B21606'}}/>
                        </a>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={10} sm={10} md={9} lg={10}>
                      <p>{this.state.customer.email}</p>
                    </Col>
                    <Col xs={2} sm={2} md={3} lg={2}>
                      <a href={`mailto:${this.state.customer.email}`}>
                        <FontAwesomeIcon icon='envelope' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                      </a>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' href={`/customers/${this.state.customer.id}/edit`}>Edit</Button> }
                { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' onClick={()=>this.handleDelete(this.state.customer.id)}>Delete</Button> }
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='mt-5' xs={12} sm={12} md={12} lg={12}>
            <h2>Cars</h2>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th width='10%'>Reg</th>
                      <th width='15%'>Car</th>
                      <th width='7.5%'>Year</th>
                      <th className='mobileRemove' width='40%'>Description</th>
                      <th width='9%'>Mileage</th>
                      <th width='8.5%'>Engine Size</th>
                      <th className='mobileExpand' width='10%'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.cars.map((car, index) =>(
                      <tr>
                        <td>{car.registration}</td>
                        <td>{car.make} {car.model}</td>
                        <td>{car.year}</td>
                        <td className='mobileRemove'>
                          <Truncate lines={1} ellipsis={<span>...</span>}>
                            {car.description}
                          </Truncate>
                        </td>
                        <td>{car.mileage}m</td>
                        <td>{car.engine_size}</td>
                        <td>
                          <a href='#' onClick={()=>this.handleDelete(car.id)}>
                            <FontAwesomeIcon icon='trash' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                          </a>
                          <a href={`/customers/${this.state.customer.id}/car/${car.id}`}>
                            <FontAwesomeIcon icon='info-circle' size='lg' style={{display:'inline-block', float:'right', color:'#B21606', marginRight:'10px'}}/>
                          </a>
                          <a href={`/customers/${this.state.customer.id}/car/${car.id}/edit`}>
                            <FontAwesomeIcon icon='edit' size='lg' style={{display:'inline-block', float:'right', color:'#B21606', marginRight:'10px'}}/>
                          </a>
                        </td>
                      </tr>
                      )
                    )}
                  </tbody>
                </Table>
          </Col>
        </Row>





        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Link to={`/customers/${this.state.customer.id}/car/new`} className="ui dark-red button">Create Car</Link>
          </Col>
        </Row>
      </Container>
      }
      return (
        <div className='main-container'>
        {data}
        </div>

      );
    }
  }

export default CustomerInfo;
