import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Spinner, Table, Breadcrumb} from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { Button, Image} from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Truncate from 'react-truncate';
import paginate from 'paginate-array';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faCheck, faTimes);

class CustomerCarInfo extends Component {
  constructor() {
    super();
    this.state = {
      car: {},
      customer: {},
      jobs: [],
      car_loading: true,
      customer_loading: true,
      jobs_loading: true,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleJobDelete = this.handleJobDelete.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
      this.setState({
        car: response.data,
        car_loading: false
      })
    })
    .catch(error => console.log('error', error));

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
    this.setState({
      customer: response.data,
      customer_loading: false
      })
    })
    .catch(error => console.log('error', error));

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
      console.log(response.data)
    this.setState({
      jobs: response.data,
      jobs_loading: false
      })
    })
    .catch(error => console.log('error', error));

  }

  handleDelete() {
    let token = "Bearer " + localStorage.getItem("jwt");

    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then(() => {
        window.location.reload();
      })
      .catch(error => console.log('error', error));
  }

  handleJobDelete(event) {
    let token = "Bearer " + localStorage.getItem("jwt");

    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs/${event}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then(() => {
        window.location.reload();
      })
      .catch(error => console.log('error', error));
  }

  handleCardClick = (id) => {
   this.props.history.push(`/api/cars/${id}`)
  }

  render() {
    let data;
    if (this.state.car_loading || this.state.jobs_loading || this.state.customer_loading)
    {
      data =
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    }
    else
    {
      data = <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item href={`/customers/search`}>Customers</Breadcrumb.Item>
              <Breadcrumb.Item href={`/customers/${this.props.match.params.customer_id}/`}>{this.state.customer.forename} {this.state.customer.surname}</Breadcrumb.Item>
              <Breadcrumb.Item active>Cars</Breadcrumb.Item>
              <Breadcrumb.Item active >{this.state.car.registration}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className='mb-5' xs={12} sm={12} md={12} lg={12}>
            <Card>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h1 style={{textAlign:'center',marginTop:'10px'}}>{this.state.car.make} {this.state.car.model} ({this.state.car.year})</h1>
                </Col>
                <Col className='mb-5' xs={12} sm={12} md={12} lg={6}>
                  <Card.Body>
                    <Col className='mb-4' xs={12} sm={12} md={12} lg={12}>
                      <Card.Title><h2>Car Details</h2></Card.Title>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Row>
                        <Col className='car-modal-details' xs={6} sm={6} md={6} lg={6}>
                          <FontAwesomeIcon className='car-modal-details-icon' icon='gas-pump' size='sm' /><p className='car-modal-details-value'> {this.state.car.fuel_type}</p><br/>
                            <FontAwesomeIcon className='car-modal-details-icon' icon='calendar-alt' size='sm' /><p className='car-modal-details-value'> {this.state.car.year}</p><br/>
                        </Col>
                        <Col className='car-modal-details' xs={6} sm={6} md={6} lg={6}>
                          <FontAwesomeIcon className='car-modal-details-icon' icon='tachometer-alt' size='sm' /> <p className='car-modal-details-value'>{this.state.car.mileage} m</p><br/>
                            <FontAwesomeIcon className='car-modal-details-icon' icon='palette' size='sm' /><p className='car-modal-details-value'> {this.state.car.colour}</p><br/>
                        </Col>
                      </Row>
                      <Row>
                        <div className='ad-title m-auto'>
                          <p className='car-modal-description'>
                            <Truncate lines={3} ellipsis={<span>...</span>}>
                              {this.state.car.description}
                            </Truncate>
                          </p>
                        </div>
                      </Row>
                    </Col>
                  </Card.Body>
                </Col>
                <Col className='mb-5' xs={12} sm={12} md={12} lg={6}>
                  <Card.Body>
                    <Card.Title><h2>Customer Details</h2></Card.Title>
                    <Card.Title><p><b>{this.state.customer.forename} {this.state.customer.surname}</b></p></Card.Title>
                    <Card.Text>
                      <Row>
                        <Col xs={10} sm={10} md={9} lg={10}>
                          <p>{this.state.customer.telephone_number}</p>
                        </Col>
                        <Col xs={2} sm={2} md={3} lg={2}>
                          <a href={`tel:${this.state.customer.telephone_number}`}><FontAwesomeIcon icon='phone' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/></a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={9} sm={9} md={8} lg={9}>
                          <p>{this.state.customer.mobile}</p>
                        </Col>
                        <Col xs={3} sm={3} md={4} lg={3}>
                          <span>
                            <a href={`sms:${this.state.customer.mobile}`}>
                              <FontAwesomeIcon icon='sms' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                            </a>
                            <a href={`tel:${this.state.customer.mobile}`}>
                              <FontAwesomeIcon icon='mobile-alt' size='lg' style={{display:'inline-block', float:'right', marginRight:'10px', color:'#B21606'}}/>
                            </a>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={10} sm={10} md={9} lg={10}>
                          <p>{this.state.customer.email}</p>
                        </Col>
                        <Col xs={2} sm={2} md={3} lg={2}>
                          <a href={`mailto:${this.state.customer.email}`}>
                            <FontAwesomeIcon icon='envelope' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                          </a>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
              <Card.Footer>
                { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' href={`/cars/${this.state.car.id}/edit`}>Edit</Button> }
                { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' onClick={()=>this.handleDelete(this.state.car.id)}>Delete</Button> }
                { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' href={`/customers/${this.props.match.params.customer_id}/car/${this.state.car.id}/job/new`}>New Job</Button> }
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h2>Jobs</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th className='mobileRemove'>Description</th>
                  <th>Estimated Time</th>
                  <th>Operator</th>
                  <th>Complete</th>
                  <th className='mobileExpand'>Details</th>
                </tr>
              </thead>
              <tbody>
                {this.state.jobs.map((job, index) =>(
                  <tr>
                    <td>{job.title}</td>
                    <td className='mobileRemove'>{job.description}</td>
                    <td>{job.estimated_time}</td>
                    <td>{job.operator}</td>
                    <td>
                      {job.complete ? <FontAwesomeIcon icon='check' size='lg' style={{display:'inline-block', float:'right', color:'green'}}/> : <FontAwesomeIcon icon='times' size='lg' style={{margin:'0 auto', color:'#B21606'}}/>}
                    </td>
                    <td className='mobileExpand'>
                      <a href=''>
                        <FontAwesomeIcon icon='trash'  onClick={()=>this.handleJobDelete(job.id)} size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                      </a>
                      <a href={`/customers/${this.props.match.params.customer_id}/car/${this.state.car.id}/job/${job.id}`}>
                        <FontAwesomeIcon icon='info-circle' size='lg' style={{display:'inline-block', float:'right', color:'#B21606', marginRight:'10px'}}/>
                      </a>
                      <a href={`/customers/${this.props.match.params.customer_id}/car/${this.state.car.id}/job/${job.id}/edit`}>
                        <FontAwesomeIcon icon='edit' size='lg' style={{display:'inline-block', float:'right', color:'#B21606', marginRight:'10px'}}/>
                      </a>
                    </td>
                  </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      }
      return (
        <div className='main-container'>
        {data}
        </div>

      );
    }
  }

export default CustomerCarInfo;
