import React from 'react';
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import { Dropdown, Button } from 'semantic-ui-react'
import { Container, Form, Row, Col } from 'react-bootstrap';

const features = [

  { key: 'PowerSteering', text: 'Power Steering', value: 'Power Steering' },
  { key: 'AC', text: 'AC', value: 'AC' },
  { key: 'ElectricWindows', text: 'Electric Windows', value: 'Electric Windows' },
  { key: 'Bluetooth', text: 'Bluetooth', value: 'Bluetooth' }
]

class PartEdit extends React.Component {
  constructor() {
    super();
    this.state =  {
      registration: '',
      make: '',
      model: '',
      year: '',
      colour: '',
      body_type: '',
      fuel_type: '',
      mileage: '',
      engine_size: '',
      transmission: '',
      doors: '',
      seats: '',
      features: [],
      description: '',
      primary_image: [],
      engine: true,
      brake_pads: true,
      front_bumper: true,
      alternator: true,
      errors: []};

    this.getDropdownValue = this.getDropdownValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getPrimaryImage = this.getPrimaryImage.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("jwt")){
      let token = "Bearer " + localStorage.getItem("jwt");
      axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/parts/${this.props.match.params.id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
        .then((response) => {
          this.setState(response.data)
        })
        .catch(error => console.log('error', error));
    }
    else{
      this.props.history.push("/")
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    axios({ method: 'patch', url: `${process.env.REACT_APP_API_ENDPOINT}/api/parts/${this.state.id}`, headers: {'Authorization': token }, data: this.state})
      .then(() => {
        this.props.history.push(`/parts/${this.state.id}`);
      })
      .catch(error => console.log('error', error));
  }

  getDropdownValue(event, {value}) {
    this.setState({ features: value });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCancel() {
    this.props.history.push(`/parts/${this.state.id}`);
  }

  getPrimaryImage(primary_image){
    this.setState({ primary_image: primary_image })
  }

  render() {
    return (
      <div>
        <Container>
          <Row className='mt-5'>
            <Col className='mb-5' xs={12} sm={12} md={12} lg={12}>
              <h1>Edit {this.state.make} {this.state.model} ({this.state.registration})</h1>



              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Registration</label>
                  <input type="text" name="registration" value={this.state.registration} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.registration && <label className='text-danger formError'>Registration {this.state.errors.registration}</label> }
                </div>
                <div className="form-group">
                  <label>Make</label>
                  <input type="text" name="make" value={this.state.make} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.make && <label className='text-danger formError'>Make {this.state.errors.make}</label> }
                </div>
                <div className="form-group">
                  <label>Model</label>
                  <input type="text" name="model" value={this.state.model} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.model && <label className='text-danger formError'>Model {this.state.errors.model}</label> }
                </div>
                <div className="form-group">
                  <label>Year</label>
                  <input type="number" name="year" value={this.state.year} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.year && <label className='text-danger formError'>Year {this.state.errors.year}</label> }
                </div>
                <div className="form-group">
                  <label>Colour</label>
                  <input type="text" name="colour" value={this.state.colour} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.colour && <label className='text-danger formError'>Colour{this.state.errors.colour}</label> }
                </div>
                <div className="form-group">
                  <Form.Label>Fuel Type</Form.Label>
                  <Form.Control as="select" value={this.state.fuel_type} name="fuel_type" onChange={this.handleChange}>
                    <option>Petrol</option>
                    <option>Diesel</option>
                    <option>Hybrid</option>
                    <option>Electric</option>
                    <option>LPG</option>
                  </Form.Control>
                  {this.state.errors.fuel_type && <label className='text-danger formError'>Fuel type {this.state.errors.fuel_type}</label> }
                </div>
                <div className="form-group">
                  <Form.Label>Body Type</Form.Label>
                  <Form.Control as="select" value={this.state.body_type} name="body_type" onChange={this.handleChange}>
                    <option>Hatchback</option>
                    <option>Estate</option>
                    <option>Saloon</option>
                    <option>Coupe</option>
                    <option>SUV</option>
                  </Form.Control>
                  {this.state.errors.body_type && <label className='text-danger formError'>Body type {this.state.errors.body_type}</label> }
                </div>
                <div className="form-group">
                  <label>Mileage</label>
                  <input type="number" name="mileage" value={this.state.mileage} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.mileage && <label className='text-danger formError'>Mileage {this.state.errors.mileage}</label> }
                </div>
                <div className="form-group">
                  <label>Engine Size</label>
                  <input type="number" name="engine_size" value={this.state.engine_size} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.engine_size && <label className='text-danger formError'>Engine size {this.state.errors.engine_size}</label> }
                </div>
                <div className="form-group">
                  <label>Transmission</label>
                  <Form.Control as="select" value={this.state.transmission} name="transmission" onChange={this.handleChange}>
                    <option>Manual</option>
                    <option>Automatic</option>
                  </Form.Control>
                  {this.state.errors.transmission && <label className='text-danger formError'>Transmission {this.state.errors.transmission}</label> }
                </div>
                <div className="form-group">
                  <label>Doors</label>
                  <Form.Control as="select" value={this.state.doors} name="doors" onChange={this.handleChange}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                  </Form.Control>
                  {this.state.errors.doors && <label className='text-danger formError'>Doors {this.state.errors.doors}</label> }
                </div>
                <div className="form-group">
                  <label>Seats</label>
                  <Form.Control as="select" value={this.state.seats} name="seats" onChange={this.handleChange}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                  </Form.Control>
                  {this.state.errors.seats && <label className='text-danger formError'>Seats {this.state.errors.seats}</label> }
                </div>
                <div className="form-group">
                  <label>Features</label>
                  <Dropdown name="features"
                  fluid multiple clearable selection options={features} onChange={this.getDropdownValue}  />
                  {this.state.errors.features && <label className='text-danger formError'>Features {this.state.errors.features}</label> }
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea name="description" rows="5" value={this.state.description} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.description && <label className='text-danger formError'>Description {this.state.errors.description}</label> }
                </div>
                <div className="form-group">
                  <label>Parts</label>
                  <Row>
                    <Col className='mb-2' xs={12} sm={6} md={4} lg={3} style={{textAlign:'center'}}>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" defaultChecked={this.state.engine} label="Engine" name="engine" value={this.state.engine} onChange={this.handleChange}  />
                      </Form.Group>
                    </Col>
                    <Col className='mb-2' xs={12} sm={6} md={4} lg={3} style={{textAlign:'center'}}>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" defaultChecked={this.state.brake_pads} label="Brake Pads" name="brake_pads" value={this.state.brake_pads} onChange={this.handleChange}  />
                      </Form.Group>
                    </Col>
                    <Col className='mb-2' xs={12} sm={6} md={4} lg={3} style={{textAlign:'center'}}>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" defaultChecked={this.state.alternator} label="Alternator" name="alternator" value={this.state.alternator} onChange={this.handleChange}  />
                      </Form.Group>
                    </Col>
                    <Col className='mb-2' xs={12} sm={6} md={4} lg={3} style={{textAlign:'center'}}>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" defaultChecked={this.state.front_bumper} label="Front Bumper" name="front_bumper" value={this.state.front_bumper} onChange={this.handleChange}  />
                      </Form.Group>
                    </Col>
                  </Row>
                  {this.state.errors.description && <label className='text-danger formError'>Description {this.state.errors.description}</label> }
                </div>
                <div className="form-group">
                  <label>Primary Image</label>
                  <FileBase64
                    accept=".jpg, .jpeg"
                    className="form-control"
                    onDone={ this.getPrimaryImage.bind(this) } />
                  {this.state.errors.primary_image && <label className='text-danger formError'>Primary Image {this.state.errors.primary_image}</label> }
                </div>
                <div className="btn-group">
                  <Button type="submit" onClick={this.handleSubmit} className="btn dark-red btn-dark">Create</Button>
                  <Button type="button" onClick={this.handleCancel} className="btn dark-red btn-secondary">Cancel</Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PartEdit;
