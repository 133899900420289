import React from 'react';
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import { Dropdown } from 'semantic-ui-react'
import { Container, Form, Row, Col, Spinner } from 'react-bootstrap';

const features = [
  { key: 'Air Conditioning', text: 'Air Conditioning', value: 'Air Conditioning' },
  { key: 'Alloy Wheels', text: 'Alloy Wheels', value: 'Alloy Wheels' },
  { key: 'Bluetooth', text: 'Bluetooth', value: 'Bluetooth' },
  { key: 'Remote Control Locking', text: 'Remote Control Locking', value: 'Remote Control Locking' },
  { key: 'Cruise Control', text: 'Cruise Control', value: 'Cruise Control' },
  { key: 'Isoflex Childseat Fitting', text: 'Isoflex Childseat Fitting', value: 'Isoflex Childseat Fitting' },
  { key: 'Parking Sensors', text: 'Parking Sensors', value: 'Parking Sensors' },
  { key: 'Roof Rails', text: 'Roof Rails', value: 'Roof Rails' },
  { key: 'Sat Nav', text: 'Sat Nav', value: 'Sat Nav' },
  { key: 'Heated Seats', text: 'Heated Seats', value: 'Heated Seats' },
  { key: 'Electric Sunroof', text: 'Electric Sunroof', value: 'Electric Sunroof' },
  { key: 'Leather Upholstery', text: 'Leather Upholstery', value: 'Leather Upholstery' }
]

class CarEdit extends React.Component {
  constructor() {
    super();
    this.state =  {
      car: {
        id: '',
        registration: '',
        make: '',
        model: '',
        year: '',
        colour: '',
        body_type: '',
        fuel_type: '',
        mileage: '',
        engine_size: '',
        transmission: '',
        doors: '',
        seats: '',
        features: [],
        description: '',
        price: '',
        primary_image: '',
        images: [],
        mot: false,
        mot_date: '',
      },
      loading: true,
      preview_primary_image: '',
      preview_images: [],
      errors: []};

    this.getDropdownValue = this.getDropdownValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEngineSizeChange = this.handleEngineSizeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getPrimaryImage = this.getPrimaryImage.bind(this);
    this.getImages = this.getImages.bind(this);
    this.handleMOTChange = this.handleMOTChange.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("jwt")){
      let token = "Bearer " + localStorage.getItem("jwt");
      axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/${this.props.match.params.id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
        .then((response) => {
          this.setState({
            car: response.data,
            loading: false,
            preview_images: response.data.images,
            preview_primary_image: response.data.primary_image
          })
        })
        .catch(error => {
          console.log(error)
          this.setState({ errors: error.response.data.error, loading: false });
        });
    }
    else{
      this.props.history.push("/")
    }

  }

  handleSubmit(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    axios({ method: 'patch', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars/${this.state.car.id}`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: this.state})
      .then(() => {
        this.props.history.push(`/cars/${this.state.car.id}`);
      })
      .catch(error => {
        this.setState({ errors: error.response.data.error, loading: false });
      });
  }

  getDropdownValue(event, {value}) {
    this.setState({ features: value });
  }


  handleEngineSizeChange(event) {
    event.preventDefault();
    this.setState(
      { [event.target.name]: event.target.type === 'number' ? parseFloat(event.target.value).toFixed(1) : event.target.value },
      this.checkPreviewStatus()
    );

  }

  handleMOTChange(event) {
    console.log(event.target.checked)
    this.setState(
      { mot: event.target.checked }
    );

  }

  handleChange(event) {
    var car = {...this.state.car}
    car[event.target.name] = event.target.type === 'number' ? parseInt(event.target.value) : event.target.value;

    if (typeof car.primary_image === 'string' || car.primary_image instanceof String)
    {
      let image_url = car.primary_image
      car.primary_image = {
        name: '',
        type: '',
        size: '',
        base64: '',
        file: image_url
      }
    }
    for (var i = 0; i < car.images.length; i++) {

      if (typeof car.images[i] === 'string' || car.images[i] instanceof String)
      {
        let image_url = car.images[i]
        car.images[i] =  {
          name: '',
          type: '',
          size: '',
          base64: '',
          file: image_url
        }
      }
    }
    this.setState({car:car});
  }

  handleCancel() {
    this.props.history.push(`/cars/${this.state.car.id}`);
  }

  getPrimaryImage(primary_image){
    this.setState({ primary_image: primary_image })
  }

  getImages(images){
    this.setState({ images: images })
  }

  render() {
    let data;

    if (this.state.loading)
    {
      data =
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    }
    else
    {

    data =
      <div>
        <Container>
          <Row className='mt-5'>
            <Col className='mb-5' xs={12} sm={12} md={12} lg={12}>
              <h1>Edit {this.state.car.make} {this.state.car.model}</h1>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Registration</label>
                  <input type="text" name="registration" value={this.state.car.registration} onChange={this.handleChange} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Make</label>
                  <input type="text" name="make" value={this.state.car.make} onChange={this.handleChange} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Model</label>
                  <input type="text" name="model" value={this.state.car.model} onChange={this.handleChange} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Year</label>
                  <input type="number" name="year" value={this.state.car.year} onChange={this.handleChange} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Colour</label>
                  <input type="text" name="colour" value={this.state.car.colour} onChange={this.handleChange} className="form-control" />
                </div>
                <div className="form-group">
                  <Form.Label>Fuel Type</Form.Label>
                  <Form.Control as="select" value={this.state.car.body_type} name="body_type" onChange={this.handleChange}>
                    <option>Petrol</option>
                    <option>Diesel</option>
                    <option>Hybrid</option>
                    <option>Electric</option>
                    <option>LPG</option>
                  </Form.Control>
                </div>
                <div className="form-group">
                  <Form.Label>Body Type</Form.Label>
                  <Form.Control as="select" value={this.state.car.fuel_type} name="fuel_type" onChange={this.handleChange}>
                    <option>Hatchback</option>
                    <option>Estate</option>
                    <option>Saloon</option>
                    <option>Coupe</option>
                    <option>SUV</option>
                  </Form.Control>
                </div>
                <div className="form-group">
                  <label>Mileage</label>
                  <input type="number" name="mileage" value={this.state.car.mileage} onChange={this.handleChange} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Engine Size</label>
                  <Form.Control type="number" ref='engine_size' step="0.1" name="engine_size" value={this.state.car.engine_size} onChange={this.handleEngineSizeChange} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Transmission</label>
                  <Form.Control as="select" value={this.state.car.transmission} name="transmission" onChange={this.handleChange}>
                    <option>Manual</option>
                    <option>Automatic</option>
                  </Form.Control>
                </div>
                <div className="form-group">
                  <label>Doors</label>
                  <Form.Control as="select" value={this.state.car.doors} name="doors" onChange={this.handleChange}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                  </Form.Control>
                </div>
                <div className="form-group">
                  <label>Seats</label>
                  <Form.Control as="select" value={this.state.car.seats} name="seats" onChange={this.handleChange}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8+</option>
                  </Form.Control>
                </div>
                <div className="form-group">
                  <label>Features</label>
                  <Dropdown
                  fluid multiple clearable selection options={features} defaultValue={this.state.car.features} onChange={this.handleChange}  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea name="description" rows="5" value={this.state.car.description} onChange={this.handleChange} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Price</label>
                  <div className="ui right fluid labeled input">
                    <label className="ui label">£</label>
                    <input type="number" name="price" value={this.state.car.price} onChange={this.handleChange} className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Primary Image</label>
                  <FileBase64
                    accept=".jpg, .jpeg"
                    className="form-control"
                    value={this.state.car.primary_image}
                    onDone={ this.getPrimaryImage.bind(this) } />
                  {this.state.errors.primary_image && <label className='formError'>{this.state.errors.primary_image}</label> }
                </div>
                <div>
                  <img alt='Car Primary' src={this.state.preview_primary_image} />
                </div>
                <div className="form-group">
                  <label>Files</label>
                  <FileBase64
                    multiple={ true }
                    accept=".jpg, .jpeg, .gif, .png"
                    className="form-control"
                    onDone={ this.getImages.bind(this) } />
                </div>
                <div>
                  {this.state.preview_images.map((image, index) => {
                    return (<img key={index} style={{"width": "20%"}} alt="dssdds" src={`${image}`} />)
                  })}
                </div>

                <Form.Group>
                  <Form.Label>MOT</Form.Label>
                  <div className="ui right fluid"> 
                    <input
                      name="mot"
                      type="checkbox"
                      checked={this.state.car.mot}
                      onChange={this.handleMOTChange} />
                  </div>
                </Form.Group>
                <Form.Group style={{ visibility: this.state.car.mot ? 'visible': 'hidden'}}>
                  <Form.Label>MOT Date</Form.Label>
                  <div className="ui right fluid"> 
                    <input
                      value={this.state.car.mot_date} 
                      ref="mot_date"
                      name="mot_date"
                      type="date"
                      onChange={this.handleChange} />
                  </div>
                </Form.Group>

                <br/>
                <div className="btn-group">
                  <button type="submit" onClick={this.handleSubmit} className="ui dark-red button">Update</button>
                  <button type="button" onClick={this.handleCancel} className="ui dark-red button">Cancel</button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    }
    return (
      <div>
        {data}
      </div>
    );
  }
}

export default CarEdit;
