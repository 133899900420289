import React from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';

class CustomerEdit extends React.Component {
  constructor() {
    super();
    this.state =  {
      customer: {
        forename: '',
        surname: '',
        address_1: '',
        address_2: '',
        town: '',
        post_code: '',
        county: '',
        telephone_number: '',
        mobile: '',
        email: '',
      },
      errors: []};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("jwt")){
      let token = "Bearer " + localStorage.getItem("jwt");
      axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
        .then((response) => {
          this.setState({
            customer: response.data
          })
        })
        .catch(error => console.log('error', error));
    }
    else{
      this.props.history.push("/")
    }

  }

  handleSubmit(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    axios({ method: 'patch', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.id}`, headers: {'Authorization': token }, data: this.state.customer})
      .then(() => {
        this.props.history.push(`/customers/${this.props.match.params.id}`);
      })
      .catch(error => console.log('error', error));
  }

  handleChange(event) {
    this.setState({
      customer:
      {
        ...this.state.customer,
        [event.target.name]: event.target.value
      }
    });
  }

  handleCancel() {
    this.props.history.push(`/customers/${this.props.match.params.id}`);
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h1>Customer Edit</h1>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Forename</label>
                  <input type="text" name="forename" value={this.state.customer.forename} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.forename && <label className='text-danger formError'>Forename {this.state.errors.forename}</label> }
                </div>
                <div className="form-group">
                  <label>Surname</label>
                  <input type="text" name="surname" value={this.state.customer.surname} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.surname && <label className='text-danger formError'>Surname {this.state.errors.surname}</label> }
                </div>
                <div className="form-group">
                  <label>Address 1</label>
                  <input type="text" name="address_1" value={this.state.customer.address_1} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.address_1 && <label className='text-danger formError'>Address {this.state.errors.address_1}</label> }
                </div>
                <div className="form-group">
                  <label>Address 2</label>
                  <input type="text" name="address_2" value={this.state.customer.address_2} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.address_2 && <label className='text-danger formError'>Address {this.state.errors.address_2}</label> }
                </div>
                <div className="form-group">
                  <label>Town</label>
                  <input type="text" name="town" value={this.state.customer.town} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.town && <label className='text-danger formError'>Town {this.state.errors.town}</label> }
                </div>
                <div className="form-group">
                  <label>Post Code</label>
                  <input type="text" name="post_code" value={this.state.customer.post_code} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.post_code && <label className='text-danger formError'>Post Code {this.state.errors.post_code}</label> }
                </div>
                <div className="form-group">
                  <label>County</label>
                  <input type="text" name="county" value={this.state.customer.county} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.county && <label className='text-danger formError'>County {this.state.errors.county}</label> }
                </div>
                <div className="form-group">
                  <label>Telephone Number</label>
                  <input type="text" name="telephone_number" value={this.state.customer.telephone_number} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.telephone_number && <label className='text-danger formError'>Telephone Number {this.state.errors.telephone_number}</label> }
                </div>
                <div className="form-group">
                  <label>Mobile</label>
                  <input type="text" name="mobile" value={this.state.customer.mobile} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.mobile && <label className='text-danger formError'>Mobile{this.state.errors.mobile}</label> }
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" name="email" value={this.state.customer.email} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.email && <label className='text-danger formError'>Email {this.state.errors.email}</label> }
                </div>
                <div className="btn-group">
                  <button type="submit" onClick={this.handleSubmit} className="ui dark-red button">Update</button>
                  <button type="button" onClick={this.handleCancel} className="ui dark-red button">Cancel</button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CustomerEdit;
