import React from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { Button} from 'semantic-ui-react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faTachometerAlt, faPalette, faGasPump, faCalendarAlt} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faTachometerAlt, faPalette, faGasPump, faCalendarAlt);

class CustomerAdd extends React.Component {
  constructor() {
    super();
    this.state =  {
      forename: '',
      surname: '',
      address_1: '',
      address_2: '',
      town: '',
      post_code: '',
      county: '',
      telephone_number: '',
      mobile: '',
      email: '',
      errors: []};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("jwt")){
      this.setState({ token: "Bearer " + localStorage.getItem("jwt") })
    }
    else{
      this.props.history.push("/")
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (localStorage.getItem("jwt")){
      let token = "Bearer " + localStorage.getItem("jwt");
      axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: this.state})
        .then((response) => {
          this.props.history.push(`/customers/${response.data.id}`);
        })
        .catch(error => this.setState({ errors: error.response.data.error }));
    }
    else{
      this.props.history.push("/")
    }
  }

  handleChange(event) {
    this.setState(
      { [event.target.name]: event.target.value }
    );
  }

  handleCancel() {
    this.props.history.push("/customers");
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h1>Upload Customer</h1>
              <p>Please provide details for customer upload, ensuring you provide as many details as possible. Any errors will return error messages beside each field.</p>
              <form id="customer-form" className="mt-5" onSubmit={this.handleSubmit}>
                <label style={{color:'red'}}>Required *</label>
                <div className="form-group">
                  <label>Forename <span style={{color:'red'}}>*</span></label>
                  <input type="text" required name="forename" value={this.state.forename} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.forename && <label className='text-danger formError'>Forename {this.state.errors.forename}</label> }
                </div>
                <div className="form-group">
                  <label>Surname <span style={{color:'red'}}>*</span></label>
                  <input type="text" required name="surname" value={this.state.surname} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.surname && <label className='text-danger formError'>Surname {this.state.errors.surname}</label> }
                </div>
                <div className="form-group">
                  <label>Address 1 <span style={{color:'red'}}>*</span></label>
                  <input type="text" required name="address_1" value={this.state.address_1} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.address_1 && <label className='text-danger formError'>Address {this.state.errors.address_1}</label> }
                </div>
                <div className="form-group">
                  <label>Address 2</label>
                  <input type="text" name="address_2" value={this.state.address_2} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.address_2 && <label className='text-danger formError'>Address {this.state.errors.address_2}</label> }
                </div>
                <div className="form-group">
                  <label>Town <span style={{color:'red'}}>*</span></label>
                  <input type="text" required name="town" value={this.state.town} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.town && <label className='text-danger formError'>Town {this.state.errors.town}</label> }
                </div>
                <div className="form-group">
                  <label>Post Code <span style={{color:'red'}}>*</span></label>
                  <input type="text" required name="post_code" value={this.state.post_code} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.post_code && <label className='text-danger formError'>Post Code {this.state.errors.post_code}</label> }
                </div>
                <div className="form-group">
                  <label>County <span style={{color:'red'}}>*</span></label>
                  <input type="text" required name="county" value={this.state.county} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.county && <label className='text-danger formError'>County {this.state.errors.county}</label> }
                </div>
                <div className="form-group">
                  <label>Telephone Number </label>
                  <input type="text" name="telephone_number" value={this.state.telephone_number} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.telephone_number && <label className='text-danger formError'>Telephone Number {this.state.errors.telephone_number}</label> }
                </div>
                <div className="form-group">
                  <label>Mobile <span style={{color:'red'}}>*</span></label>
                  <input type="text" required name="mobile" value={this.state.mobile} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.mobile && <label className='text-danger formError'>Mobile{this.state.errors.mobile}</label> }
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" required name="email" value={this.state.email} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.email && <label className='text-danger formError'>Email {this.state.errors.email}</label> }
                </div>
                <div className="btn-group">
                  <Button type="submit" onClick={this.handleSubmit} className="ui dark-red button">Create</Button>
                  <Button type="button" onClick={this.handleCancel} className="ui dark-red button">Cancel</Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CustomerAdd;
