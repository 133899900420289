import React from 'react';
import axios from 'axios';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Button} from 'semantic-ui-react';

class CustomerJobEdit extends React.Component {
  constructor() {
    super();
    this.state =  {
      customer: {},
      car_id: '',
      customer_id: '',
      job: {
        title: '',
        description: '',
        estimated_time: '',
        operator: '',
        complete: false
      },
      errors: []};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then((response) => {
        this.setState({ customer : response.data})
      })
      .catch(error => console.log('error', error));

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs/${this.props.match.params.job_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then((response) => {
        console.log(response.data)
        this.setState({ job : response.data})
      })
      .catch(error => console.log('error', error));

  }

  handleSubmit(event) {
    var form_data = {
        'car_id': this.props.match.params.car_id,
        'customer_id': this.props.match.params.customer_id,
        'title': this.state.job.title,
        'description': this.state.job.description,
        'estimated_time': this.state.job.estimated_time,
        'complete': this.state.job.complete,
        'operator': this.state.job.operator,
    }
    console.log(form_data)
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    axios({ method: 'patch', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs/${this.props.match.params.job_id}`, headers: {'Authorization': token }, data: form_data})
      .then(() => {
        this.props.history.push(`/customers/${this.props.match.params.customer_id}/car/${this.props.match.params.car_id}`);
      })
      .catch(error => console.log('error', error));
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({
      job:{
        ...this.state.job,
        [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value
      }
    });
  }

  handleCancel() {
    this.props.history.push(`/customers/${this.state.id}/jobs/new`);
  }


  handleCheckboxChange(event) {
    event.preventDefault();
    this.setState({
      job:{
        ...this.state.job,
        complete: event.target.checked
      }
    })
  }


  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col className='mt-4' xs={12} sm={12} md={12} lg={12}>
              <h1>Job for {this.state.forename} {this.state.surname}</h1>
              <form onSubmit={this.handleSubmit}>
                <Form.Group>
                  <label>Title</label>
                  <input type="text" name="title" value={this.state.job.title} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.title && <label className='text-danger formError'>Surname {this.state.errors.title}</label> }
                </Form.Group>
                <Form.Group>
                  <label>Description</label>
                  <textarea as="textarea" id="description" name="description" defaultValue={this.state.job.description} onChange={this.handleChange} rows="3" className="form-control" />
                  {this.state.errors.description && <label className='text-danger formError'>Address {this.state.errors.description}</label> }
                </Form.Group>
                <Form.Group>
                  <label>Estimated Time</label>
                  <input type="number" name="estimated_time" value={this.state.job.estimated_time} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.estimated_time && <label className='text-danger formError'>Address {this.state.errors.estimated_time}</label> }
                </Form.Group>
                <Form.Group>
                  <label>Operator</label>
                  <input type="text" name="operator" value={this.state.job.operator} onChange={this.handleChange} className="form-control" />
                  {this.state.errors.operator && <label className='text-danger formError'>Town {this.state.errors.operator}</label> }
                </Form.Group>
                <Form.Group>
                  <Form.Label>Complete</Form.Label>
                  <Form.Control type="checkbox" checked={this.state.job.complete} name='complete' label='Complete' onChange={this.handleCheckboxChange} />
                </Form.Group>
                <div className="btn-group">
                  <Button type="button" onClick={this.handleCancel} className="ui dark-red button">Cancel</Button>
                  <Button type="submit" onClick={this.handleSubmit} className="ui dark-red button">Update</Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CustomerJobEdit;
