import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class CustomerList extends Component {
  constructor() {
    super();
    this.state = { customers: [], loading: true};
  }

  componentDidMount() {
    if (localStorage.getItem("jwt")){
      let token = "Bearer " + localStorage.getItem("jwt");
      axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
        .then(response => {
          this.setState({ customers: response.data, loading: false })
        })
        .catch(error => console.log('error', error));
    }
    else{
      this.props.history.push("/")
    }
  }

  render() {
    return (
      <Container>
        <h1 className="pageHeader">Customers</h1>
        <Row>
          {this.state.customers.map((customer, index) =>(
              <Col xs={12} sm={12} md={6} lg={4} key={index}>
                <Card>
                  <Card.Body>
                    <Card.Title>{customer.forename} {customer.surname}</Card.Title>
                    <Card.Text>
                      {customer.address_1}
                      {customer.address_2}
                    </Card.Text>
                    <Card.Img variant="top" key={index} src={`${customer.post_code}`} />
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">{customer.telephone_number}</small>
                    <Card.Link href={`customers/$ {customer.id}`}>Card Link</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
            )
          )}
        </Row>
      <Link to="/customers/new" className="btn btn-outline-primary">Create Customer</Link>
      </Container>
    )
  }
}

export default CustomerList;
