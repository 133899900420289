import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import Services from './Services';
import CarSearch from './cars/CarSearch';
import CarInfo from './cars/CarInfo';
import CarAdd from './cars/CarAdd';
import CarEdit from './cars/CarEdit';
import CustomerList from './customers/CustomerList';
import CustomerSearch from './customers/CustomerSearch';
import CustomerInfo from './customers/CustomerInfo';
import CustomerAdd from './customers/CustomerAdd';
import CustomerEdit from './customers/CustomerEdit';
import CustomerCarInfo from './customers/cars/CustomerCarInfo';
import CustomerCarEdit from './customers/cars/CustomerCarEdit';
import CustomerCarCreate from './customers/cars/CustomerCarCreate';

import CustomerJobCreate from './customers/jobs/CustomerJobCreate';
import CustomerJobInfo from './customers/jobs/CustomerJobInfo';
import CustomerJobEdit from './customers/jobs/CustomerJobEdit';
import CustomerJobInvoice from './customers/jobs/CustomerJobInvoice';

import PartList from './parts/PartList';
import PartSearch from './parts/PartSearch';
import PartInfo from './parts/PartInfo';
import PartAdd from './parts/PartAdd';
import PartEdit from './parts/PartEdit';
import Admin from './Admin';
import Header from './includes/Header'
import Footer from './includes/Footer'
import Contact from './Contact';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom'

const REACT_APP_URL = (process.env.NODE_ENV === 'development')
    ? 'http://localhost:3001'
    : 'https://mcelroy-motors-app.appspot.com';

class App extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    let data;
    if (false)
      {
        data =
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
      }
      else
      {
        data =
          <div className="App">
            <Router>
              <Header />
              <Main />
              <Footer />
            </Router>
          </div>
        }
    return (
      <div>
        {data}
      </div>
    );
  }
}

const Main = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/services" component={Services} />
    <Route exact path="/cars/search" component={CarSearch} />
    <Route exact path="/cars/new" component={CarAdd} />
    <Route exact path="/cars/:id" component={CarInfo} />
    <Route exact path="/cars/:id/edit" component={CarEdit} />

    <Route exact path="/customers" component={CustomerList} />
    <Route exact path="/customers/search" component={CustomerSearch} />
    <Route exact path="/customers/new" component={CustomerAdd} />
    <Route exact path="/customers/:id" component={CustomerInfo} />
    <Route exact path="/customers/:id/edit" component={CustomerEdit} />
    <Route exact path="/customers/:customer_id/car/new" component={CustomerCarCreate} />
    <Route exact path="/customers/:customer_id/car/:car_id/edit" component={CustomerCarEdit} />
    <Route exact path="/customers/:customer_id/car/:car_id" component={CustomerCarInfo} />
    <Route exact path="/customers/:customer_id/car/:car_id/job/new" component={CustomerJobCreate} />
    <Route exact path="/customers/:customer_id/car/:car_id/job/:job_id/edit" component={CustomerJobEdit} />
    <Route exact path="/customers/:customer_id/car/:car_id/job/:job_id" component={CustomerJobInfo} />
    <Route exact path="/customers/:customer_id/car/:car_id/job/:job_id/invoice" component={CustomerJobInvoice} />

    <Route exact path="/parts" component={PartList} />
    <Route exact path="/parts/search" component={PartSearch} />
    <Route exact path="/parts/new" component={PartAdd} />
    <Route exact path="/parts/:id" component={PartInfo} />
    <Route exact path="/parts/:id/edit" component={PartEdit} />

    <Route exact path="/contact" component={Contact} />
    <Route exact path="/admin" component={Admin} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route render={() => <Redirect to={{pathname: "/"}} />} />

  </Switch>
);

export default App;
