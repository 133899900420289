import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faMapMarketAlt, faPhone, faEnvelopeOpen, faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { Navbar, Nav, Col, Row, Image, Container } from 'react-bootstrap';
import {BrowserRouter as Link} from 'react-router-dom'
import jwt from 'jsonwebtoken';
// import MapModal from './mapModal'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';

library.add(faUser, faSignInAlt, faSignOutAlt, faFacebookSquare, faEnvelopeOpen );

class Header extends Component {

  constructor() {
    super();
    var isExpired = false;
    var isAdmin = false;
    if (localStorage.getItem('jwt')) {
      const token = localStorage.getItem('jwt');
      var decodedToken=jwt.decode(token, {complete: true});
      var dateNow = new Date();
      console.log(decodedToken.payload.exp);
      if(decodedToken.payload.exp < dateNow.getTime())
      {
        isExpired = false;
        isAdmin = true;
        localStorage.setItem("admin", true);

      }
      else
      {
        isExpired = true;
        isAdmin = false;
        localStorage.setItem("admin", false);
      }
    };

    this.state = {
      isAdmin: isAdmin,
      modalOpen: false
    };
  }

/*
  handleModalOpen = () => {
    this.setState((prevState) => {
      return{
        modalOpen: !prevState.modalOpen
      }
    })
  }
*/

  render() {
    return (
      <div id='navbar-container'>
        <Container>
          <Navbar>
            <Nav className="mr-auto">
              <Navbar.Brand href="#home">
                <Image src="/images/McElroy_Motors.gif" fluid />
              </Navbar.Brand>
            </Nav>
            <Nav style={{top:'0'}}>
              <Navbar.Collapse className="justify-content-end">
                <table className='header-icon-table'>
                  <tbody>
                    <tr className='call-us-container'>
                      <td>
                        <p style={{fontSize:'1.4em', fontWeight:'600'}}>
                          <Nav.Link className='header-icon-link' style={{color:'#B21606'}} href="tel:+447886597807">
                            <FontAwesomeIcon className="account-access-icon" style={{color:'black', marginRight:'5px'}} size='sm' icon="phone" /> 07886597807
                          </Nav.Link>
                        </p>
                      </td>
                      <td className='header-icon-td' style={{float:'right'}}>
                        <p style={{fontSize:'1.4em', fontWeight:'600'}}>
                          <Nav.Link className='header-icon-link nav-link' href='/contact' style={{color:'red',display:'inline-block'}}>
                            <FontAwesomeIcon className="account-access-icon" size='lg' icon="map-marker-alt" />
                          </Nav.Link>
                        </p>
                      </td>
                      <td className='header-icon-td' style={{float:'right'}}>
                        <p style={{fontSize:'1.4em', fontWeight:'600'}}>
                          {/*<Nav.Link className='header-icon-link' onClick={this.handleModalOpen} className="nav-link" href='mailto:info@mcelroymotors.com'  style={{color:'black',display:'inline-block'}}><FontAwesomeIcon className="account-access-icon" size='lg' icon="envelope-open" /></Nav.Link>*/}
                          <Nav.Link className='header-icon-link nav-link' target="_blank" style={{padding: '0 !important' }} href='https://www.facebook.com/mcelroymotors' style={{color:'black',display:'inline-block'}}>
                            <FontAwesomeIcon className="account-access-icon" size='lg' icon={['fab', 'facebook-square']} />
                          </Nav.Link>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Navbar.Collapse>
            </Nav>
          </Navbar>
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <Nav className="mr-auto menu-options">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/cars/search">Used Cars</Nav.Link>
                  {/*<Nav.Link href="/parts/search">Used Car Parts</Nav.Link>*/}
                <Nav.Link href="/services">Services & MOT</Nav.Link>
                <Nav.Link href="/contact">Contact Us</Nav.Link>
                { this.state.isAdmin && <Nav.Link href="/admin">Admin</Nav.Link> }
              </Nav>
              <Nav>
                <Row>
                  <Col style={{paddingBottom: '5px'}}>
                    {
                       this.state.isAdmin ?
                        <Nav.Link className="nav-link" href="/logout"><FontAwesomeIcon className="account-access-icon" size='lg' icon="sign-out-alt" /></Nav.Link>
                      :
                        <Nav.Link className="nav-link" href="/login"><FontAwesomeIcon className="account-access-icon" size='lg' icon="sign-in-alt" /></Nav.Link>
                    }
                  </Col>
                </Row>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>

      {/*  <MapModal
           modalOpen={this.state.modalOpen}
           handleModalOpen={this.handleModalOpen}
        /> */}
      </div>
    );
  }
}

export default Header;
