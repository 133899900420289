import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { Container, Form, Row, Col, ButtonToolbar, Card, Image, Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import FileBase64 from 'react-file-base64';
import { Dropdown, Header } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt);

class CustomerCarEdit extends React.Component {
  constructor() {
    super();
    this.state =  {
      customer_id: '',
      car: {
        registration: '',
        make: '',
        model: '',
        year: 0,
        colour: '',
        body_type: '',
        fuel_type: '',
        mileage: 0,
        engine_size: 0.0,
        transmission: '',
        for_sale: false,
        description: '',
      },
      clientSideErrors: {
        registration: '',
        make: '',
        model: '',
        year: '',
        colour: '',
        body_type: '',
        fuel_type: '',
        mileage: '',
        engine_size: '',
        transmission: '',
        description: '',
      },
      errors: []};
    this.getDropdownValue = this.getDropdownValue.bind(this);
    this.clientSideValid = this.clientSideValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEngineSizeChange = this.handleEngineSizeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }


  componentDidMount() {
    this.setState({ customer_id: this.props.match.params.customer_id });

    let token = "Bearer " + localStorage.getItem("jwt");

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
      console.log(response.data)
      this.setState({
        car: response.data
      })
    })
    .catch(error => console.log('error', error));
  }

  handleSubmit(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem('jwt');
    console.log(this.state.car)
    axios({ method: 'patch', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: this.state.car})
      .then((response) => {
        console.log(response)
        this.props.history.push(`/customers/${this.state.customer_id}`);
      })
      .catch(error => this.setState({ errors: error.response.data.error }));
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({
      car:{
        ...this.state.car,
        [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value
      }
    });
  }

  handleEngineSizeChange(event) {
    event.preventDefault();
    this.setState(
      { [event.target.name]: event.target.type === 'number' ? parseFloat(event.target.value).toFixed(1) : event.target.value }
    );

  }

  clientSideValid(event) {
    const { name, value } = event.target;
    let clientSideErrors = this.state.clientSideErrors;
    switch (name) {
      case 'registration':
        clientSideErrors.registration =
          value.length < 5
            ? 'Registration must be at least 5 characters long.'
            : '';
        break;
      case 'make':
        clientSideErrors.make =
          value.length == ''
            ? 'Make must be selected.'
            : '';
        break;
      case 'model':
        clientSideErrors.model =
          value.length <= 0
            ? 'Model must be selected.'
            : '';
        break;
      case 'year':
        clientSideErrors.year =
          value > parseFloat(new Date().getFullYear()) || value < 1940
            ? 'Year must be between 1950 and ' + new Date().getFullYear()
            : '';
        break;
      case 'colour':
        clientSideErrors.colour =
          value.length <= 0
            ? 'Colour must be selected.'
            : '';
        break;
      case 'body_type':
        clientSideErrors.body_type =
          value.length <= 0
            ? 'Body type must be selected.'
            : '';
        break;
      case 'fuel_type':
        clientSideErrors.fuel_type =
          value.length < 5
            ? 'Fuel type must be selected.'
            : '';
        break;
      case 'mileage':
        clientSideErrors.mileage =
          value > 1000000 || value <= 0
            ? 'Mileage must be between 0 and 1,000,000 miles.'
            : '';
        break;
      case 'engine_size':
        clientSideErrors.engine_size =
          value > 0.5 || value <= 8
            ? 'Engine size must be selected.'
            : '';
        break;
      case 'transmission':
        clientSideErrors.transmission =
          value.length <= 0
            ? 'Transmission must be selected.'
            : '';
        break;
      case 'description':
        clientSideErrors.description =
          value.length <= 0
            ? 'Description must be selected.'
            : '';
        break;
      default:
        break;
    }
    //
    // Object.entries(clientSideErrors).map(([key,value])=>{
    //   if (value !== '')
    //   {
    //     this.myTextInput.focus();
    //     console.log("s")
    //     console.log(this.myTextInput)
    //   }
    // })
    this.setState({
      clientSideErrors: clientSideErrors
    })
  }

  getDropdownValue(event, {value}) {
    this.setState({ features: value });
  }

  handleCancel() {
    this.props.history.push("/cars");
  }

  render() {
    return (
      <div className='main-container'>
        <Container>
          <Header as='h1'>Edit Car</Header>
          <p>Please provide details for car upload, ensuring you provide as many details as possible. Any errors will return error messages beside each field.</p>
          <Form id="car-form" className="mt-5" onSubmit={this.handleSubmit}>
            <Form.Control type="hidden" ref={(input) => { this.myTextInput= input; }} name="customer_id" value={this.props.match.params.customer_id} className="form-control" />
            <Form.Group>
              <Form.Label>Registration</Form.Label>
              <Form.Control type="text" ref={(name) => { this.myTextInput= name; }} name="registration" value={this.state.car.registration} onChange={this.handleChange} style={{ textTransform: 'uppercase'}} onBlur={this.clientSideValid} className="form-control" />
              {this.state.errors.registration && <Form.Label className='text-danger formError'>Registration {this.state.errors.registration}</Form.Label> }
              {this.state.clientSideErrors.registration.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.registration}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Make</Form.Label>
              <Form.Control type="text" ref={(input) => { this.myTextInput= input; }} name="make" value={this.state.car.make} onChange={this.handleChange} className="form-control" />
              {this.state.errors.make && <Form.Label className='text-danger formError'>Make {this.state.errors.make}</Form.Label> }
              {this.state.clientSideErrors.make.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.make}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Model</Form.Label>
              <Form.Control type="text" ref={(input) => { this.myTextInput= input; }} name="model" value={this.state.car.model} onChange={this.handleChange} className="form-control" />
              {this.state.errors.model && <Form.Label className='text-danger formError'>Model {this.state.errors.model}</Form.Label> }
              {this.state.clientSideErrors.model.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.model}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Year</Form.Label>
              <Form.Control type="number" ref="year" name="year" value={this.state.car.year} onChange={this.handleChange} onBlur={this.clientSideValid} className="form-control" />
              {this.state.errors.year && <Form.Label className='text-danger formError'>Year {this.state.errors.year}</Form.Label> }
              {this.state.clientSideErrors.year.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.year}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Colour</Form.Label>
              <Form.Control type="text" ref='colour' name="colour" value={this.state.car.colour} onChange={this.handleChange} className="form-control" />
              {this.state.errors.colour && <Form.Label className='text-danger formError'>Colour{this.state.errors.colour}</Form.Label> }
              {this.state.clientSideErrors.colour.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.colour}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Fuel Type</Form.Label>
              <Form.Control ref='fuel_type' as="select" value={this.state.car.fuel_type} name="fuel_type" onChange={this.handleChange}>
                <option>Petrol</option>
                <option>Diesel</option>
                <option>Hybrid</option>
                <option>Electric</option>
                <option>LPG</option>
              </Form.Control>
              {this.state.errors.fuel_type && <Form.Label className='text-danger formError'>Fuel type {this.state.errors.fuel_type}</Form.Label> }
              {this.state.clientSideErrors.fuel_type.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.fuel_type}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Body Type</Form.Label>
              <Form.Control ref='body_type' as="select" value={this.state.car.body_type} name="body_type" onChange={this.handleChange}>
                <option>Hatchback</option>
                <option>Estate</option>
                <option>Saloon</option>
                <option>Coupe</option>
                <option>SUV</option>
              </Form.Control>
              {this.state.errors.body_type && <Form.Label className='text-danger formError'>Body type {this.state.errors.body_type}</Form.Label> }
              {this.state.clientSideErrors.body_type.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.body_type}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Mileage</Form.Label>
              <Form.Control type="number" ref='mileage' name="mileage" value={this.state.car.mileage} onChange={this.handleChange} className="form-control" />
              {this.state.errors.mileage && <Form.Label className='text-danger formError'>Mileage {this.state.errors.mileage}</Form.Label> }
              {this.state.clientSideErrors.mileage.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.mileage}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Engine Size</Form.Label>
              <Form.Control type="number" ref='engine_size' step="0.1" name="engine_size" value={this.state.car.engine_size} onChange={this.handleEngineSizeChange} className="form-control" />
              {this.state.errors.engine_size && <Form.Label className='text-danger formError'>Engine size {this.state.errors.engine_size}</Form.Label> }
              {this.state.clientSideErrors.engine_size.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.engine_size}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Transmission</Form.Label>
              <Form.Control as="select" ref='transmission' value={this.state.car.transmission} name="transmission" onChange={this.handleChange}>
                <option>Manual</option>
                <option>Automatic</option>
              </Form.Control>
              {this.state.errors.transmission && <Form.Label className='text-danger formError'>Transmission {this.state.errors.transmission}</Form.Label> }
              {this.state.clientSideErrors.transmission.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.transmission}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" ref={(input) => { this.myTextInput= input; }} name="description" value={this.state.car.description} onChange={this.handleChange} className="form-control" />
              {this.state.errors.description && <Form.Label className='text-danger formError'>Description {this.state.errors.description}</Form.Label> }
              {this.state.clientSideErrors.description.length > 0 &&
                <span className='error'>{this.state.clientSideErrors.description}</span>}
            </Form.Group>
            <ButtonToolbar>
              <Button variant="success" type="submit" onClick={this.handleSubmit} className={`ui dark-red button`}>Update</Button>
              <Button variant="danger" type="button" onClick={this.handleCancel} className="ui dark-red button">Cancel</Button>
            </ButtonToolbar>
          </Form>
        </Container>
      </div>
    );
  }
}

export default CustomerCarEdit;
