import React, {Component} from 'react';
import { Container, Row, Col, Table} from 'react-bootstrap';
import { Button, Image} from 'semantic-ui-react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf'

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {},
      job: {},
      tasks: [],
      customer_loading: true,
      job_loading: true,
      task_loading: true,
    };
    this.printDocument = this.printDocument.bind(this);
  }


  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
    this.setState({
      customer: response.data,
      customer_loading: false
      })
    })
    .catch(error => console.log('error', error));


    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs/${this.props.match.params.job_id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
    this.setState({
      job: response.data,
      job_loading: false
      })
    })
    .catch(error => console.log('error', error));

    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/customers/${this.props.match.params.customer_id}/customercars/${this.props.match.params.car_id}/jobs/${this.props.match.params.job_id}/tasks`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
    .then((response) => {
    this.setState({
      tasks: response.data,
      task_loading: false
      })
    })
    .catch(error => console.log('error', error));
  }

  printDocument() {
    const input = document.getElementById('job-invoice');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save(this.state.customer.forename + this.state.customer.surname + "-Invoice.pdf");
      })
    ;
  }

  getTotal() {
    const rowTotals = this.state.tasks.map(
      task => (Number(task.material_cost) + Number(task.labour_cost)) || 0
    );
    let grandTotal = rowTotals.reduce((a, b) => a + b, 0)
    return grandTotal;
  }

  render() {
    let grantTotal = this.getTotal();
    return (<div>
      <Container>
        <Row className='mt-5'>
          <Col style={{backgroundColor: '#D5D8DC', paddingTop: '10px', paddingBottom: '10px'}} xs={12} sm={12} md={12} lg={12}>
            <div className="mt-10" id="job-invoice" style={{backgroundColor: '#ffffff', width: '200mm', minHeight: '287mm',marginLeft: 'auto', marginRight: 'auto', padding: '5mm'}}>

              <Image style={{margin: '0 auto'}} src='/images/McElroy_Motors.gif' />
              <Row className='mt-5'>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <p>McElroy Motors<br/>
                  65, Drumnakilly Road<br/>
                  Omagh<br/>
                  Co. Tyrone<br/>
                  BT79 0JP</p>
                  <p>Tel: 07886597807<br/>
                  Email: invoice@mcelroymotors.com</p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <p style={{float: 'right', textAlign: 'right'}}>{this.state.customer.forename} {this.state.customer.surname}<br/>
                    {this.state.customer.address_1}<br/>
                    {this.state.customer.town}<br/>
                    Co. {this.state.customer.county}<br/>
                    {this.state.customer.post_code}
                  </p>
                </Col>
              </Row>
              <Row className='mt-5'>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Work</th>
                        <th>Time Taken</th>
                        <th>Operator</th>
                        <th>Parts Used</th>
                        <th>Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tasks.map((task, index) =>(
                        <tr key={task.id}>
                          <td>{task.name}</td>
                          <td>{task.description}</td>
                          <td>{task.actual_time}</td>
                          <td>{task.operator}</td>
                          <td>{task.parts_used}</td>
                          <td>{Number(task.material_cost) + Number(task.labour_cost)}</td>
                        </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <br/>
                  <Table bordered hover style={{float:'right', width:'30%'}}>
                    <thead>
                      <tr>
                        <th>Total Cost</th>
                        <th>£ {grantTotal.toFixed(2)}</th>
                      </tr>
                      <tr>
                        <th>VAT (20%)</th>
                        <th>£ { ( ( grantTotal / 100 ) * 20 ).toFixed(2) }</th>
                      </tr>
                      <tr>
                        <th>Total</th>
                        <th>£ { ( grantTotal + ( ( grantTotal / 100 ) * 20 ) ).toFixed(2) }</th>
                      </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={12} sm={12} md={12} lg={9}>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3}>
            <Button className='ui dark-red button' onClick={this.printDocument}>Download</Button>
          </Col>
        </Row>

      </Container>
    </div>);
  }
}

export default Invoice;
