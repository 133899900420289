import React, { Component } from 'react';
import axios from 'axios';
import { Jumbotron, Row, Col, Image, Container, Table, Card, Carousel, Spinner} from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import {BrowserRouter as NavLink, Link} from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

library.add( faFacebook );

const mapStyles = {
  width: '100%',
  height: '100%'
};

const LoadingContainer = (props) => (
  <div>
    <Spinner animation='border' role='status'>
      <span className='sr-only'>Loading...</span>
    </Spinner>
  </div>
)

export class Home extends Component {
  constructor() {
    super();
    this.state = {
      cars: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      loading: true
    };
  }

  componentDidMount() {
    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/cars`, headers: {'Content-Type': 'application/json' }})
      .then(response => {
        console.log(response.data);
        this.setState({ cars: response.data, loading: false })
      })
      .catch(error => console.log('error', error));
  }

  render() {
    return (
      <div>
        <Carousel className='homepage-carousel'>
          <Carousel.Item>
            <Jumbotron style={{ backgroundImage: `url('images/slide-1.jpg')` }}>
              <Carousel.Caption>
                <h1>Welcome to McElroy Motors - Established in 1981 </h1>
              </Carousel.Caption>
            </Jumbotron>
          </Carousel.Item>

          <Carousel.Item>
            <Jumbotron style={{ backgroundImage: `url('images/slide-2.jpg')` }}>
              <Carousel.Caption>
                <h1>For all your automotive needs...</h1>
              </Carousel.Caption>
            </Jumbotron>
          </Carousel.Item>

          <Carousel.Item>
            <Jumbotron style={{ backgroundImage: `url('images/slide-3.jpg')` }}>
              <Carousel.Caption>
                <h1>
                  To stay connected, follow us on
                  <span>
                    <Button href='https://www.facebook.com/mcelroymotors' target="_blank" className="facebook-button ui button" style={{color:'#FFFFFF', backgroundColor:'#4267B2', padding: '0.5em 1em', fontSize: '1.5rem', marginLeft:'10px'}}>
                      <FontAwesomeIcon style={{color:'#FFFFFF', marginRight:'10px'}} size='lg' icon={['fab', 'facebook-square']} />
                      Facebook
                    </Button>
                  </span>
                </h1>
              </Carousel.Caption>
            </Jumbotron>
          </Carousel.Item>
        </Carousel>

        <Container className="mt-5">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h1>Welcome to McElroy Motors - Established in 1981</h1>
              <p>
                Established in 1981 by Frank McElroy and now owned and managed by his eldest son John since 2013,
                McElroy Motors is a licensed garage offering an extensive range of car services.
              </p>

              <p>
                Located 3 miles outside Omagh, Co.Tyrone, the business has grown from operating as a specialised mechanic
                to servicing and selling used cars, car breakage, parts, diagnostics and body work.
              </p>

              <p>
                As a family run, local business, customers can be assured all their car needs are managed in a friendly
                and professional way.  With a 40 year legacy, the garage has built an enviable reputation for providing
                a transparent and thorough standard of service, the customer loyalty built up over the years is a
                testament to this.
              </p>

            </Col>
          </Row>
        </Container>

        <Container className="mt-5">
          <Row>
            {this.state.cars.slice(0, 4).map((car, index) =>(
                <Col xs={12} sm={12} md={6} lg={3} key={index}>
                  <Card>
                    <Card.Body>
                      <Card.Title>{car.make} {car.model} - {car.engine_size}L <br/>({car.year})</Card.Title>
                      <Card.Img variant="top" key={index} src={`${car.primary_image}`} />
                    </Card.Body>
                    <Card.Footer>
                      <span className="text-muted">£{car.price.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                      <Card.Link style={{float:'right'}} href={`cars/${car.id}`}>View Details</Card.Link>
                    </Card.Footer>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </Container>
        <Container className="mt-5" >
          <Row>
            <Col className='mb-3' xs={12} sm={12} md={6} lg={4}>
              <Card style={{height: '100%'}} className="text-center">
                <Card.Header>
                  <h2>Opening Hours</h2>
                </Card.Header>
                <Card.Body style={{fontSize:'1.7em'}}>
                  <Table borderless={ true } >
                    <tr>
                      <td style={{textAlign: 'left'}}>Monday</td>
                      <td style={{textAlign: 'center'}}>8:00 - 18:00</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'left'}}>Tuesday</td>
                      <td style={{textAlign: 'center'}}>8:00 - 18:00</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'left'}}>Wednesday</td>
                      <td style={{textAlign: 'center'}}>8:00 - 18:00</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'left'}}>Thursday</td>
                      <td style={{textAlign: 'center'}}>8:00 - 18:00</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'left'}}>Friday</td>
                      <td style={{textAlign: 'center'}}>8:00 - 18:00</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'left'}}>Saturday</td>
                      <td style={{textAlign: 'center'}}>9:00 - 13:00</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'left'}}>Sunday</td>
                      <td style={{textAlign: 'center'}}>Closed</td>
                    </tr>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col className='mb-3' xs={12} sm={12} md={6} lg={4}>
              <Card className="text-center">
                <Card.Header>
                  <h2>Testimonials</h2>
                </Card.Header>
                <Card.Body>
                  <div className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <Image style={{width: '30%', left:'35%'}} roundedCircle src="/images/avatar.png"  />
                        <Card.Text style={{color:'black', fontSize:'1.3em', fontWeight:'bold'}}>John Smith</Card.Text>
                        <Card.Text style={{color:'black', fontSize:'1.2em', fontStyle:'bold'}}>
                          Car broke down on the way to work one morning. Gave mcelroy motors a call and they were there within half an hour. <br/><br/>
                          John went above and beyond to make sure I got moving again after my car broke down. Cant recommend him enough...
                        </Card.Text>
                      </div>
                      <div className="carousel-item">
                        <Image style={{width: '30%', left:'35%'}} roundedCircle src="/images/avatar.png"  />
                          <p style={{color:'black', fontSize:'1.05em'}}>Name</p>
                        <Card.Text style={{color:'black'}}>
                            Sample testimonial text
                        </Card.Text>
                      </div>
                      <div className="carousel-item">
                        <Image style={{width: '30%', left:'35%'}} roundedCircle src="/images/avatar.png"  />
                        <Card.Text style={{color:'black'}}>
                            Sample testimonial text
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col className='mb-3' xs={12} sm={12} md={12} lg={4}>

              <div style={{height: '100%'}}>
                <Map
                  google={this.props.google}
                  zoom={12}
                  style={mapStyles}
                  onReady={this.handleGoogleMapLoaded}
                  initialCenter={{
                   lat: 54.602688,
                   lng: -7.233257
                  }}>
                  <Marker
                    title={'The marker`s title will appear as a tooltip.'}
                    name={'SOMA'}
                    position={{lat: 54.602688, lng: -7.233257}} />
                  <InfoWindow onClose={this.onInfoWindowClose}>
                    <div>
                      <h1>{this.state.selectedPlace.name}</h1>
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            {/*
            <Card className="text-center">
              <Card.Header>
                <h2>Cars For Sale</h2>
              </Card.Header>
              <Card.Body>
                <Card.Title>Second hand cars, quality assured</Card.Title>
                <Card.Text>
                  Our range of cars are fully services and guaranteed for one year.
                </Card.Text>
                <Button className="ui dark-red button">View Cars</Button>
              </Card.Body>
            </Card>
            */}
          </Col>

        </Row>


      </Container>


      </div>
    );
  }
}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyBUDqY784BtEBpKMIW9Ih3iCvki2Z4fNws',
  LoadingContainer: LoadingContainer
})(Home);
