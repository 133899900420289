import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Spinner, Pagination } from 'react-bootstrap';
import { Button, Image, Label, Grid, Input } from 'semantic-ui-react';
import Slider, { Range } from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import "semantic-ui-css/semantic.min.css";
import ImageGallery from 'react-image-gallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Truncate from 'react-truncate';
import paginate from 'paginate-array';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt);

const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
    <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class PartSearch extends Component {
  constructor() {
    super();
    const createSliderWithTooltip = Slider.createSliderWithTooltip;
    const Range = createSliderWithTooltip(Slider.Range);
    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(20),( val, index) => year - index);
    this.state = {
      make: '',
      model: '',
      colour: '',
      parts: [],
      makeModelList: [],
      loading: true,
      years: years,
      features: [],
      selectedFeatures: [],
      makes: [],
      models: [],
      size: 4,
      page: 1,
      currPage: null};

    this.goToPage = this.goToPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.generateFormValues = this.generateFormValues.bind(this);
  }

  componentDidMount() {
    this.setState({
      parts: '',
      loading: false,
    });
    {/*axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/parts`})
      .then(response => {
        this.setState({
          parts: response.data,
          loading: false,
        });
        this.generateFormValues(this.state.parts);
        this.generatePagination(this.state)
      })
      .catch(error => console.log('error', error));*/}
  }


  generatePagination = (state) => {
    const { page, size } = this.state;

    const currPage = paginate(this.state.parts, page, size);

    let active = currPage;
    let items = [];
    for (let number = 1; number <= currPage.totalPages; number++) {
      items.push(
        <Pagination.Item key={number} data-index={number} active={number === active} onClick={e => { this.goToPage(e)}}>
          {number}
        </Pagination.Item>,
      );
    }
    this.setState({
      ...this.state,
      currPage,
      items
    })
  }

  generateFormValues = (parts) => {
    let makeModelArray = [];
    let makeArray = [];
    let minPriceAvailable = 0;
    let maxPriceAvailable = 0;
    makeArray.push(<option key={1} value=''>Please Select</option>);
    {parts.map((part, index) => {
      part.features.map((feature) => {
        if(this.state.features.indexOf(feature) < 0){
          this.setState((state) => ({
            features: state.features.concat([feature])
          }))
        }
      });
      if (part.price >= maxPriceAvailable)
        maxPriceAvailable = parseInt(part.price);
      if ((part.price <= minPriceAvailable) || (minPriceAvailable === 0))
        minPriceAvailable = parseInt(part.price);
      let make = makeModelArray.filter(make_model => (make_model.make === part.make))
      let model = makeModelArray.filter(make_model => (make_model.models === part.model))
      if( make === undefined || make.length === 0) {
        makeArray.push(<option key={part.make} value={part.make}>{part.make}</option>);
        makeModelArray.push({ make: `${part.make}`, models: [`${part.model}`] });
      }
      else if ( model === undefined || model.length === 0) {
        for (var i=0; i < makeModelArray.length; i++) {
          let model_1 = makeModelArray[i].models.filter(model => (model === part.model));
          if (makeModelArray[i].make === part.make && model_1.length === 0){
            makeModelArray[i].models.push(part.model);
            }
          else {
          }
        }
      }
    })}
    this.setState({
      makeModelList: makeModelArray,
      makes: makeArray,
      minPrice: minPriceAvailable,
      maxPrice: maxPriceAvailable
    });
  }

  handleDelete = (id) => {
    let token = "Bearer " + localStorage.getItem("jwt");
    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/parts/${id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then(() => {
        this.props.history.push("/parts")
      })
      .catch(error => console.log('error', error));
  }

  handleMakeChange = (event) => {
    let models = [];
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index]
    var option =  optionElement.getAttribute('value');
    this.state.makeModelList.map((make_model, index) => {
      if (option === make_model.make){
        make_model.models.map((model, index) => {
          models.push(<option key={model} value={model}>{model}</option>);
        })
        this.setState({
          models: models
        })
      }
    })
  }

  handlePriceChange = (value) => {
    this.setState({
      minPriceSelected: value[0],
      maxPriceSelected: value[1]
    });
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value}, function () {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/parts/search/`,
        data: {
          make: this.state.make,
          model: this.state.model,
          year: this.state.year,
          colour: this.state.colour,
          min_price: this.state.minPriceSelected,
          max_price: this.state.maxPriceSelected,
          features: this.state.selectedFeatures
        }
      })
      .then(response => {
        this.setState({
          parts: response.data,
          loading: false})
      })
      .catch(error => console.log('error', error));
    })
    this.generatePagination(this.state);

    if(event.target.name === 'make')
    {
      this.handleMakeChange(event);
    }
    if(event.target.name === 'year')
    {
      this.handleMakeChange(event);
    }
    if(event.target.name === 'feature')
    {
      let feature = event.target.value;
      let checked = event.target.checked;
      if (checked === true){
        this.setState((state) => ({
          selectedFeatures: this.state.selectedFeatures.concat([feature])
        }))
      }
      else if (checked === false) {
        let features = [...this.state.selectedFeatures]
        let index = features.indexOf(feature)
        features.splice(index, 1);
        this.setState({selectedFeatures: features});
      }
    }
    if(event.target.name === 'price')
    {
    }
  };

  goToPage = (event) => {
    const { currPage, page, size, parts } = this.state;
    const newPage = event.target.getAttribute("data-index");
    const newCurrPage = paginate(parts, newPage, size);

    this.setState({
      ...this.state,
      page: newPage,
      currPage: newCurrPage
    });
  }

  render() {
    const { page, size, currPage } = this.state;
    let data;
    if (this.state.loading)
    {
      data =
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    }
    else
    {

    data =
      <div>
        <Container style={{ margin: '0 auto'}}>
          <Image src="/images/coming-soon.png" fluid style={{ width:'80%', margin: '0 auto', paddingTop: '150px' }}/>
          {/*<Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h1 className='page-header'>Parts Inventory</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={4} lg={3}>
              <Form.Group controlId='formMake'>
                <Form.Label>Make</Form.Label>
                <Form.Control name='make' as='select' placeholder='Make' value={this.state.make} onChange={e => { this.handleChange(e)}} >
                  { this.state.makes }
                </Form.Control>
              </Form.Group>
              <Form.Group controlId='formModel'>
                <Form.Label>Model</Form.Label>
                <Form.Control name='model' as='select' value={this.state.model} onChange={this.handleChange} >
                  <option key='0' value=''>Please select</option>
                  { this.state.models }
                </Form.Control>
              </Form.Group>
              <Form.Group controlId='formColour'>
                <Form.Label>Year</Form.Label>
                <Form.Control name='year' as='select' value={this.state.year} onChange={this.handleChange} >
                  <option key='0' value=''>Please Select</option>
                  {this.state.years.map((year, index) => <option key={index}>{year}</option>)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={8} lg={9}>
              {currPage &&
                <div>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={9}>
                      <Pagination>
                        <Pagination.Item data-index={1} onClick={this.goToPage}>First</Pagination.Item>
                        {this.state.items}
                        <Pagination.Item data-index={this.state.currPage.totalPages} onClick={this.goToPage}>Last</Pagination.Item>
                      </Pagination>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3}>
                      <Form.Group controlId='formColour'>
                        <Form.Control style={{float:'right'}} name='sort' as='select' value={this.state.sort} onChange={this.handleChange} >
                          <option key='0' value=''>Sort By...</option>
                          <option value='price'>Price</option>
                          <option value='year'>Year</option>
                          <option value='mileage'>Mileage</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row id='ads'>
                      {currPage.data.map((part, index) => {
                        return(
                          <Col xs={12} sm={12} md={6} lg={6} key={index}>
                            <Card>
                              <Card.Body>
                                <Card.Title>{part.make} {part.model}</Card.Title>
                                <Card.Img variant='top' key={index} src={`${part.primary_image}`} />
                                <Card.Text>
                                <Truncate lines={1} ellipsis={<span>...</span>}>
                                  {part.description}
                                </Truncate>
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer>
                                <Button className='ui dark-red button' href={`/parts/${part.id}`}>Details</Button>
                                { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' href={`/parts/${part.id}/edit`}>Edit</Button> }
                                { (localStorage.getItem('jwt')) && <Button className='ui dark-red button' onClick={()=>this.handleDelete(part.id)}>Delete</Button> }
                              </Card.Footer>
                            </Card>
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                }
            </Col>
          </Row>*/}
        </Container>
      </div>
    }
    return (
      <div className='main-container'>
      {data}
      </div>

    );
  }
}

export default PartSearch;
