import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col, Container, Card, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faEnvelope, faPhone, faTrash, faSms, faMobile, faMobileAlt} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

library.add(faPoundSign, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faEnvelope, faPhone, faTrash, faSms, faMobile, faMobileAlt);

export class Admin extends Component {

    constructor() {
      super();
      this.state = {
        messages: [],
        loading: true
      };
    }

    componentDidMount() {
      if (localStorage.getItem("jwt")){
        let token = "Bearer " + localStorage.getItem('jwt');
        axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/messages`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }})
          .then(response => {
            this.setState({ messages: response.data, loading: false })
          })
          .catch(error => {
            this.setState({ error: error.response.data, loading: false });
            localStorage.removeItem("jwt");
            this.props.history.push("/");
          });
      }
      else {
        this.props.history.push("/");
      }
    }

    handleDelete = (id) => {
      let token = "Bearer " + localStorage.getItem("jwt");
      axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/messages/${id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
        .then(() => {
          this.setState({ loading: false })
          window.location.replace("/admin");
        })
        .catch(error => console.log('error', error));
    }

    render() {
      let data;

      if (this.state.loading)
      {
        data =
        <Spinner animation='border' role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      }
      else
      {

      data =
        <div>
          <Container>
            <Row>
              <h1 className="page-header">Admin</h1>
            </Row>
          </Container>
          <Container className='Body-container'>
            <Row>
              <Col className='mb-2' xs={12} sm={12} md={6} lg={4}>
                <Card className="text-center">
                  <Card.Header>
                    <Card.Title><h2>Cars For Sale</h2></Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Button className='mb-4 ui dark-red button' href='/cars/search' variant="outline-primary" size="lg" block>
                      Search Cars
                    </Button>
                    <Button className='mb-4 ui dark-red button' href='/cars/search' variant="outline-primary" size="lg" block>
                      Edit Car
                    </Button>
                    <Button className='mb-4 ui dark-red button' href='/cars/new' variant="outline-primary" size="lg" block>
                      Upload Car
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col className='mb-2' xs={12} sm={12} md={6} lg={4}>
                <Card className="text-center">
                  <Card.Header>
                    <Card.Title><h2>Parts</h2></Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Button className='mb-4 ui dark-red button' href='/parts/search' variant="outline-primary" size="lg" block>
                      Search Parts
                    </Button>
                    <Button className='mb-4 ui dark-red button' href='/parts/search' variant="outline-primary" size="lg" block>
                      Edit Part
                    </Button>
                    <Button className='mb-4 ui dark-red button' href='/parts/new' variant="outline-primary" size="lg" block>
                      Upload Part
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col className='mb-2' xs={12} sm={12} md={6} lg={4}>
                <Card className="text-center">
                  <Card.Header>
                    <Card.Title><h2>Customers</h2></Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Button className='mb-4 ui dark-red button' href='/customers/search' variant="outline-primary" size="lg" block>
                      Search Customers
                    </Button>
                    <Button className='mb-4 ui dark-red button' href='/customers/search' variant="outline-primary" size="lg" block>
                      Edit Customer
                    </Button>
                    <Button className='mb-4 ui dark-red button' href='/customers/new' variant="outline-primary" size="lg" block>
                      Upload Customer
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h1 className="page-header">Messages</h1>
                  </Col>
                  {this.state.messages.map((message, index) =>(
                  <Col className='mt-5' xs={12} sm={12} md={12} lg={12} key={index}>
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          <p><b>Source: </b>
                          { message.source === 'Contact' &&
                            <span>{message.source}<br/></span>
                          }
                          { message.source === 'Cars' &&
                            <span>{message.source} - <a href={`/cars/${message.source_id}`} className='ui red'>{message.registration}</a><br/></span>
                          }
                          { message.source === 'Parts' &&
                            <span>{message.source} - <a href={`/cars/${message.source_id}`} className='ui red'>{message.registration}</a><br/></span>
                          }
                          <span>{message.name} ({message.telephone})</span></p>
                        </Card.Title><br/>
                        <Card.Text>{message.message_text}</Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <Card.Link style={{float:'right'}} href='#' >
                        </Card.Link>
                        <Card.Link style={{float:'right'}} href='#delete' onClick={()=>this.handleDelete(message.id)}>
                          <FontAwesomeIcon icon='trash' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                        </Card.Link>
                        {
                          (message.telephone.startsWith("00353") || message.telephone.startsWith("+353") || message.telephone.startsWith("07") || message.telephone.startsWith("044") || message.telephone.startsWith("+44") || message.telephone.startsWith("+7")) &&
                            <Card.Link style={{float:'right'}} href={`sms:${message.telephone}`}>
                              <FontAwesomeIcon icon='sms' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                            </Card.Link>
                        }
                        <Card.Link style={{float:'right'}} href={`mailto:${message.email}`}>
                          <FontAwesomeIcon icon='envelope' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                        </Card.Link>
                        <Card.Link style={{float:'right'}} href={`tel:${message.telephone}`}>
                          <FontAwesomeIcon icon='phone' size='lg' style={{display:'inline-block', float:'right', color:'#B21606'}}/>
                        </Card.Link>
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              )}
            </Row>
          </Container>
          <br/>
        </div>
      }
      return (
        <div>
          {data}
        </div>
      );
    }
  }


export default Admin;
