import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faAt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '600px',
  minHeight: '600px'
};

const LoadingContainer = (props) => (
  <div>
    <Spinner animation='border' role='status'>
      <span className='sr-only'>Loading...</span>
    </Spinner>
  </div>
)

library.add(faPhone, faAt, faMapMarkerAlt );

export class Contact extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      contactName: '',
      contactEmail: '',
      contactTelephone: '',
      contactMessage: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      loading: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClear = () => {
    document.getElementById("contact-form").reset();
    this.setState({
      contactName:'',
      contactEmail: '',
      contactTelephone: '',
      contactMessage: ''
    });
  }

  handleChange(event) {
    event.preventDefault();
    this.setState(
      { [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value }
    );
  }

  handleSubmit(event) {
    var form_data = {
                      'name': this.state.contactName,
                      'email': this.state.contactEmail,
                      'telephone': this.state.contactTelephone,
                      'message_text': this.state.contactMessage,
                      'source': 'Contact'
                    }
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem('jwt');
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/messages`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: form_data})
      .then((response) => {
        console.log(this.state)
      })
      .catch(error => this.setState({ errors: error.response.data.error }));
  }

  render() {
    return (
      <div>
        <div className='map-container'>
          <Map
            google={this.props.google}
            zoom={16}
            style={mapStyles}
            className='contact-map'
            onReady={this.handleGoogleMapLoaded}
            initialCenter={{
             lat: 54.602688,
             lng: -7.233257
            }}
          />
          <Marker
            title={'The marker`s title will appear as a tooltip.'}
            name={'SOMA'}
            position={{
              lat: 54.602688,
              lng: -7.233257
            }}
          />
          <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>{this.state.selectedPlace.name}</h1>
            </div>
          </InfoWindow>
        </div>
        <Container className='body-container'>
          <Row>
            <Col className='contact-form-container' xs={12} sm={12} md={6} lg={8}>
              <h2 className='contact-form-header'>Contact Form</h2>
              <Form id='contact-form'>
                <Form.Group controlId='contactForm.ControlInput1'>
                  <Form.Label>Name <span style={{color:'red'}}>*</span></Form.Label>
                  <Form.Control type='name' name='contactName' required onChange={this.handleChange} />
                  {this.state.errors.name && <Form.Label className='text-danger formError'>Name {this.state.errors.name}</Form.Label> }
                </Form.Group>
                <Form.Group controlId='contactForm.ControlInput2'>
                  <Form.Label>Email <span style={{color:'red'}}>*</span></Form.Label>
                  <Form.Control type='email' name='contactEmail' required onChange={this.handleChange}/>
                  {this.state.errors.email && <Form.Label className='text-danger formError'>Email address {this.state.errors.email}</Form.Label> }
                </Form.Group>
                <Form.Group controlId='contactForm.ControlSelect1'>
                  <Form.Label>Mobile/Telephone <span style={{color:'red'}}>*</span></Form.Label>
                  <Form.Control type='telephone' name='contactTelephone' required onChange={this.handleChange} />
                  {this.state.errors.telephone && <Form.Label className='text-danger formError'>Phone number {this.state.errors.telephone}</Form.Label> }
                </Form.Group>
                <Form.Group controlId='exampleForm.ControlTextarea1'>
                  <Form.Label>Message<span style={{color:'red'}}>*</span></Form.Label>
                  <Form.Control as='textarea' rows='5' name='contactMessage' required onChange={this.handleChange} />
                  {this.state.errors.message_text && <Form.Label className='text-danger formError'>Message {this.state.errors.message_text}</Form.Label> }
                </Form.Group>
                <Form.Group controlId='exampleForm.ControlTextarea1'>
                  <Button variant="danger" type="button" onClick={()=>this.handleClear()} className="ui dark-red button">Clear</Button>
                  <Button variant="success" style={{float:'right'}} type="submit" onClick={this.handleSubmit} className={`ui dark-red button`}>Submit</Button>
                </Form.Group>
              </Form>
            </Col>
            <Col className='contact-details-container' xs={12} sm={12} md={6} lg={4}>
              <h2 className='contact-details-header'>Contact Details</h2>
              <div className='contact-icon-container'>
                <Row>
                  <Col xs={2} sm={2} md={3} lg={2}>
                    <FontAwesomeIcon className='contact-icon' icon='phone' size='2x' style={{display:'inline-block', float:'right'}}/>
                  </Col>
                  <Col xs={10} sm={10} md={9} lg={10}>
                    <p className='contact-icon' style={{margin:'15px 10px', fontSize:'1.4em'}}>
                      <a href='tel:+447886597807'>07886597807</a>
                    </p>
                  </Col>
                </Row>
              </div>
              <div className='contact-icon-container'>
                <Row>
                  <Col xs={2} sm={2} md={3} lg={2}>
                    <FontAwesomeIcon className='contact-icon' icon='at' size='2x' style={{display:'inline-block', float:'right'}}/>
                  </Col>
                  <Col xs={10} sm={10} md={9} lg={10}>
                    <p className='contact-icon' style={{margin:'15px 10px', fontSize:'1.4em'}}>
                      <a href='mailto:info@mcelroymotors.com'>info@mcelroymotors.com</a>
                    </p>
                  </Col>
                </Row>
              </div>
              <div className='contact-icon-container'>
                <Row>
                  <Col xs={2} sm={2} md={3} lg={2}>
                    <FontAwesomeIcon className='contact-icon' icon='map-marker-alt' size='2x' style={{display:'inline-block', float:'right'}}/>
                  </Col>
                  <Col xs={10} sm={10} md={9} lg={10}>
                    <p className='contact-icon' style={{margin:'15px 10px', fontSize:'1.4em'}}>65, Drumnakilly Road</p>
                    <p className='contact-icon' style={{margin:'15px 10px', fontSize:'1.4em'}}>Omagh</p>
                    <p className='contact-icon' style={{margin:'15px 10px', fontSize:'1.4em'}}>Co. Tyrone</p>
                    <p className='contact-icon' style={{margin:'15px 10px', fontSize:'1.4em'}}>BT79 0JP</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBUDqY784BtEBpKMIW9Ih3iCvki2Z4fNws',
  LoadingContainer: LoadingContainer
})(Contact);
