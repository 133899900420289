import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Spinner, Image, Modal, Form, Button } from 'react-bootstrap';
import { Link} from 'react-router-dom'
import ImageGallery from 'react-image-gallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faComment, faCheck, faTimes, faQuestion} from '@fortawesome/free-solid-svg-icons';

library.add(faPoundSign, faCar, faTachometerAlt, faPalette, faGasPump, faCalendarAlt, faComment, faCheck, faTimes, faQuestion);

class PartInfo extends Component {
  constructor() {
    super();
    this.state = {
      part: {},
      showModal: false,
      loading: true,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.renderPartModal = this.renderPartModal.bind(this);
    this.handlePartModalOpen = this.handlePartModalOpen.bind(this);
    this.handlePartModalClose = this.handlePartModalClose.bind(this);
    this.handleEnquiryChange = this.handleEnquiryChange.bind(this);
    this.handleEnquirySubmit = this.handleEnquirySubmit.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    axios({method: 'get', url: `${process.env.REACT_APP_API_ENDPOINT}/api/parts/${this.props.match.params.id}`, headers: {'Content-Type': 'application/json', 'Authorization': token }})
      .then((response) => {
        this.setState({
          part: response.data,
          loading: false
        })
      })
      .catch(error => console.log('error', error));
  }

  handleDelete() {
    axios({ method: 'delete', url: `${process.env.REACT_APP_API_ENDPOINT}/api/parts/${this.props.match.params.id}`})
      .then(() => {
        this.props.history.push("/parts")
      })
      .catch(error => console.log('error', error));
  }

  handleCardClick = (id) => {
   this.props.history.push(`/parts/${id}`)
  }

 handleEnquiryChange(event) {
   event.preventDefault();
   this.setState(
     { [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value }
   );
 }


  renderPartModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.showPartModal === true) {
      return (
        <div>

          <Modal.Header closeButton>
            <Modal.Title id='enquiry-modal-title'>
              Enquire about <b>{this.state.modalPart}</b> for <b>{this.state.part.make} {this.state.part.model} - {this.state.part.engine_size}l ({this.state.part.year})</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className='rounded' style={{border:'none'}}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Form id='contact-form'>
                    <Form.Group controlId='contactForm.ControlInput1'>
                      <Form.Control type='name' name='enquiryName' placeholder='Name' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlInput2'>
                      <Form.Control type='email' name='enquiryEmail' placeholder='Email' onChange={this.handleEnquiryChange}/>
                    </Form.Group>
                    <Form.Group controlId='contactForm.ControlSelect1'>
                      <Form.Control type='telephone' name='enquiryTelephone' placeholder='Mobile/Telephone' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Form.Control as='textarea' rows='5' name='enquiryMessage' placeholder='Message' onChange={this.handleEnquiryChange} />
                    </Form.Group>
                    <Form.Group controlId='exampleForm.ControlTextarea1'>
                      <Button variant="danger" type="button" onClick={()=>this.handleClear()} className="ui dark-red button">Clear</Button>
                      <Button variant="success" style={{float:'right'}} type="submit" onClick={this.handleEnquirySubmit} className={`ui dark-red button`}>Submit</Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
        </div>
      );
    }
  }

  handlePartModalOpen = (car_part) => {
    this.setState({
      modalPart: car_part,
      showPartModal: true
    })
  }

  handlePartModalClose(){
    this.setState({ showPartModal: false });
  }


  handleEnquirySubmit(event){
    var form_data = {
                      'name': this.state.enquiryName,
                      'email': this.state.enquiryEmail,
                      'telephone': this.state.enquiryTelephone,
                      'message_text': this.state.enquiryMessage,
                      'registration': this.state.part.registration,
                      'part': this.state.modalPart,
                      'source_id': this.state.part.id,
                      'source': 'Parts'
                    }
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem('jwt');
    axios({ method: 'post', url: `${process.env.REACT_APP_API_ENDPOINT}/api/messages`, headers: {'Content-Type': 'application/json','Authorization': token, 'Access-Control-Allow-Origin': '*' }, data: form_data})
      .then((response) => {
        this.setState({ showEnquiryModal: false });
      })
      .catch(error => this.setState({ errors: error.response.data.error }));
  }
  render() {
    let data;
    if (this.state.loading)
    {
      data =
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    }
    else
    {
      data = <Container>
        <Row id="ads">
          <Col xs={12} sm={12} md={12} lg={12}  key={this.state.part.id}>
            <Card className='rounded' style={{border:'none'}}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className='card-image'>
                    <Image thumbnail src={`${this.state.part.primary_image}`}   />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <Row>
                    <Col className='car-modal-details' xs={6} sm={6} md={6} lg={4}>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='car' size='sm' /><p className='car-modal-details-value'> {this.state.part.body_type}</p><br/>
                    </Col>
                    <Col className='car-modal-details' xs={6} sm={6} md={6} lg={4}>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='gas-pump' size='sm' /><p className='car-modal-details-value'> {this.state.part.fuel_type}</p><br/>
                    </Col>
                    <Col className='car-modal-details' xs={6} sm={6} md={6} lg={4}>
                      <Image className='car-modal-details-icon' src='../images/car-seat.png' /><p className='car-modal-details-value'>{this.state.part.seats} seats</p><br/>
                    </Col>
                    <Col className='car-modal-details' xs={6} sm={6} md={6} lg={4}>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='tachometer-alt' size='sm' /> <p className='car-modal-details-value'>{this.state.part.mileage} miles</p><br/>
                    </Col>
                    <Col className='car-modal-details' xs={6} sm={6} md={6} lg={4}>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='palette' size='sm' /><p className='car-modal-details-value'> {this.state.part.colour}</p><br/>
                    </Col>
                    <Col className='car-modal-details' xs={6} sm={6} md={6} lg={4}>
                      <FontAwesomeIcon className='car-modal-details-icon' icon='calendar-alt' size='sm' /><p className='car-modal-details-value'> {this.state.part.year}</p><br/>
                    </Col>
                    <Col className='car-modal-details' xs={6} sm={6} md={6} lg={4}>
                      <Image className='car-modal-details-icon' src='../images/car-door.png' /><p className='car-modal-details-value'>{this.state.part.doors} doors</p><br/>
                    </Col>
                  </Row>
                  <Card.Body className='text-center'>
                    <div className='ad-title m-auto'>
                      <h2>Features</h2>
                        <p className='car-modal-details-features'>
                          {this.state.part.features.map(function(feature, index) {
                            return <span className='car-model-features' key={index}>{ (index ? ' - ' : '') + feature }</span>;
                          })}
                        </p>
                      <p className='car-modal-description'>{this.state.part.description}</p>
                    </div>
                  </Card.Body>
                </Col>
              </Row>
              <Row>
                <Col className='car-modal-details' xs={12} sm={6} md={4} lg={3}>
                  <p className='car-modal-details-value'>Engine &nbsp;
                    {this.state.part.engine ?
                      <span><FontAwesomeIcon className='car-modal-details-icon' icon='check' size='sm' /><br/><Link onClick={()=>this.handlePartModalOpen(this.state.part.engine)}><FontAwesomeIcon className='car-modal-details-icon' icon='comment' size='sm' /></Link></span> :
                      <FontAwesomeIcon className='car-modal-details-icon' icon='times' size='sm' />
                    }</p><br/>
                </Col>
                <Col className='car-modal-details' xs={12} sm={6} md={4} lg={3}>
                  <p className='car-modal-details-value'>Brake Pads &nbsp;
                    {this.state.part.brake_pads ?
                      <span><FontAwesomeIcon className='car-modal-details-icon' icon='check' size='sm' /><br/><Link onClick={()=>this.handlePartModalOpen(this.state.part.brake_pads)}><FontAwesomeIcon className='car-modal-details-icon' icon='comment' size='sm' /></Link></span> :
                      <FontAwesomeIcon className='car-modal-details-icon' icon='times' size='sm' />
                    }</p><br/>
                </Col>
                <Col className='car-modal-details' xs={12} sm={6} md={4} lg={3}>
                  <p className='car-modal-details-value'>Alternator &nbsp;
                    {this.state.part.alternator ?
                      <span><FontAwesomeIcon className='car-modal-details-icon' icon='check' size='sm' /><br/><Link onClick={()=>this.handlePartModalOpen('Alternator')}><FontAwesomeIcon className='car-modal-details-icon' icon='comment' size='sm' /></Link></span> :
                      <FontAwesomeIcon className='car-modal-details-icon' icon='times' size='sm' />
                    }</p><br/>
                </Col>
                <Col className='car-modal-details' xs={12} sm={6} md={4} lg={3}>
                  <p className='car-modal-details-value'>Front Bumper &nbsp;
                    {this.state.part.front_bumper ?
                      <span><FontAwesomeIcon className='car-modal-details-icon' icon='check' size='sm' /><br/><Link onClick={() => this.handlePartModalOpen('Front Bumper')}><FontAwesomeIcon className='car-modal-details-icon' icon='comment' size='sm' /></Link></span> :
                      <FontAwesomeIcon className='car-modal-details-icon' icon='times' size='sm' />
                    }</p><br/>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      }
      return (
        <div className='main-container'>
          {data}
          <Modal show={this.state.showPartModal}
                 onHide={this.handlePartModalClose}
                 size="xl"
                 centered>
            {this.renderPartModal()}
          </Modal>
        </div>

      );
    }
  }

export default PartInfo;
